import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useWeb3Context } from '../../context'
import { appLoadDataAsyncThunk } from '../../redux/pagesSlices/emolume_bank'
import CustomButton from '../customs/CustomButton'
import moment from 'moment'

const InfoCard = ({ value, title, icon, col }) => (
  <div className={`col-lg-${col ?? 4}`}>
    <div className='dashboard-card'>
      <div className='icon-box'>
        <i className={`fa-duotone ${icon}`}></i>
      </div>
      <div className='content'>
        <h3>{value}</h3>
        <small>{title}</small>
      </div>
    </div>
  </div>
)

export default function Default() {
  const { analytics } = useSelector(state => state.bank)
  const dispatch = useDispatch()
  const { web3Provider, address, network, } = useWeb3Context()
  const { myReward,
    tetherInContract,
    claimedDevelopmentTeamReward,
    developmentTeamReward,
    totalSupplyLimit,
    collectedUsdtInSwap,
    totalSupply,
    dbonPerUSDT,
    usdtContractAddresses,
    ldbnContractAddresses,
    dbonContractAddresses,
    isOwner,
    whiteListedAddressesCount,
    isManager,
    dbonOwner,
    rewardMinted,
    claimRewardTime,
    dues,
    isTransferable,
    dbonBankAddress,
    dbonBalance,
    wlisted,
  } = analytics;
  const subString = e => e.length > 10 ? e.substring(0, 4) + "..." + e.substring(e.length - 4, e.length) : e;
  const infoList = [
    { value: dbonBalance.toFixed(2), title: "My DBN Balance", icon: "fa-e" },
    { value: totalSupplyLimit.toFixed(2), title: "Total Supply", icon: "fa-building-columns" },
    // { value: collectedUsdtInSwap.toFixed(2), title: "Total swaped USDT", icon: "fa-building-columns" },
    { value: tetherInContract.toFixed(2), title: "USDT in Treasury", icon: "fa-building-columns" },
    { value: totalSupply.toFixed(2), title: `Issued Supply (%${((totalSupply*100)/totalSupplyLimit).toFixed(2)})`, icon: "fa-ticket" },
    { value: dbonPerUSDT.toFixed(2), title: "DBN / USDT", icon: "fa-circle-dollar" },
    { value: myReward.toFixed(2), title: "My USDT Reward", icon: "fa-trophy" },
    // { value: developmentTeamReward.toFixed(2), title: "Development Team Reward" ,icon:"fa-trophy-star"},
    { value: claimedDevelopmentTeamReward.toFixed(2), title: "claimed DT Reward", icon: "fa-check-to-slot" },
    { value: (100000000 - claimedDevelopmentTeamReward).toFixed(2), title: "Remaining Dev Reward", icon: "fa-reply-clock" },
    { value: rewardMinted.toFixed(2), title: "Public Reward Minted", icon: "fa-check-to-slot" },
    { value: claimRewardTime ? moment(claimRewardTime * 1000).format('MM/DD') : "Not Started", title: "Dev Reward Time", icon: "fa-timer" },
    { value: dues.toFixed(2), title: "Dues", icon: "fa-rotate-left" },
    { value: whiteListedAddressesCount, title: "White Listed Addresses", icon: "fa-list" },
    { value: subString(dbonBankAddress), title: "Isuer Address in DBN", icon: "fa-file-signature" },
    { value: subString(usdtContractAddresses), title: "USDT Address in Issuer", icon: "fa-file-signature", col: 4 },
    { value: subString(dbonContractAddresses), title: "DBN Address in Issuer", icon: "fa-file-signature", col: 4 },
    { value: subString(dbonContractAddresses), title: "LDBN Address in Issuer", icon: "fa-file-signature", col: 4 },
  ]
  return (
    <div className='content-body'>
      <h2 className='dashboard-title'>Analytics</h2>
      <div>
        <h2>Hi {isOwner ? 'Admin' : 'User'}</h2>
        <p>{isOwner ? 'You can manage your bank from here all available methods are listed on left sidebar' : "Hi user you can view your account detail like current balance, deposit balance, rewards and more."}</p>
        <div className='row'>
          {infoList.map((el, i) => <InfoCard {...el} key={i} />)}
        </div>
        <CustomButton
          title="Reload Data"
          type="submit"
          slice='bank'
          variant="warning"
          btnClass="create-btn"
          action={"appLoadDataAsyncThunk"}
          onClick={() => dispatch(appLoadDataAsyncThunk({ networkID: network?.chainId, provider: web3Provider, address }))}
        />
      </div>
    </div>
  )
}