import React, { useState } from "react";

import AOS from "aos";
import "aos/dist/aos.css";

// import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap";
import { Web3Button } from "../components/web3UI/Web3Button";
import { Link as RouterLink } from "react-router-dom";
import { Web3ButtonMobile } from "../components/web3UI/Web3ButtonMobile";
import { addresses, supportedChainIds } from '../constants'
import { useWeb3Context } from "../context";
import { getRewatdAsyncThunk } from '../redux/pagesSlices/emolume_bank';
import { useDispatch } from "react-redux";
import { switchNetwork } from "../hooks/Web3CustomHooks";
// import { logDOM } from '@testing-library/react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Spinner } from "react-bootstrap";

import BannerImg from '../images/banner-img.png'

AOS.init();
export default function Index() {
    const [show, setShow] = useState(false);
    const [isLoading, setIsLoading] = useState(null)

    const dispatch = useDispatch();
    const { web3Provider, connect, disconnect, network, error, provider, address } = useWeb3Context()
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(null);
    const success = () => toast("Added to whitelist!");
    const failure = () => toast("Adding to whitelist failed!");

    const addToWhiteList = () => {
        setIsLoading(true)
        fetch('https://dashbone.com/api/addresses', {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'accept': 'application/json'
            },
            body: JSON.stringify({
                "address": address,
                "ref": "dashbone.io"
            })
        })
            .then((result) => {
                return result.json()
            })
            .then((data) => {
                if (data.errors) {
                    console.log(data.errors)
                    toast(data.errors.address[0])
                    setIsLoading(false)
                }
                else {
                    success()
                    setIsLoading(false)
                }
            })

            .catch((err) => {
                if (err) {
                    toast(err.message)
                }
            })
    }

    return (
        <div>
            <div className="header">
                <nav className="navbar navbar-expand-lg navbar-light bg-light">
                    <div className="container">
                        <a className="navbar-brand" href="#">
                            <img
                                className="main_logo"
                                src={require("../images/logo.png")}
                                style={{ width: "200px" }}
                            />
                        </a>
                        {/* <RouterLink to={"/dashboard"}>
                            <button className='btn main-btn'>Dashboard</button>
                        </RouterLink> */}
                        {/* </NavLink> */}
                        <div className="mbl-btns">
                            <RouterLink to={"/dashboard"}>
                                <span className="navbar-toggler-icon">
                                    <i className="fa-solid fa-grid-2"></i>
                                </span>
                            </RouterLink>
                            <Web3ButtonMobile />
                        </div>
                        {/* <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"><i className="fa-regular fa-wallet"></i></span>
                        </button> */}
                        <div
                            className="collapse navbar-collapse"
                            id="navbarSupportedContent"
                        >
                            <ul className="navbar-nav me-auto mb-2 mb-lg-0 collapse navbar-collapse justify-content-end">
                                <li className="nav-item">
                                    <a className="nav-link nav-address"
                                        href={`https://bscscan.com/address/${addresses[56].dbonAddress}`}
                                        target="_blank">
                                        {addresses[56].dbonAddress}
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="https://twitter.com/DashBone1">
                                        <i className="fa-brands fa-twitter"></i>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="https://discord.gg/KVpw6gjzPf">
                                        <i className="fa-brands fa-discord"></i>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <RouterLink className="nav-link" to={"/swap"}>
                                        <button className="btn main-btn">Swap Token</button>
                                    </RouterLink>
                                </li>
                                <li className="nav-item">
                                    <RouterLink className="nav-link" to={"/dashboard"}>
                                        <button className="btn main-btn">Dashboard</button>
                                    </RouterLink>
                                </li>
                                {!address ? <li className="nav-item">
                                    <div onClick={() => toast("Connect wallet to proceed!")} target='_blank' className="nav-link">
                                        <button className="btn main-btn">Dashbone.ai</button>
                                    </div>
                                </li> : ""}
                                {address ? <li className="nav-item">
                                    <a href='https://dashbone.ai/' target='_blank' className="nav-link">
                                        <button className="btn main-btn">Dashbone.ai</button>
                                    </a>
                                </li> : ""}
                                <li className="nav-item">
                                    <Web3Button />
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
            <div className="container">
                <div className="main_container">
                    <div className="top_msg">
                        <marquee width="100%" direction="left">
                            Launching price 0.008$ / Token, For private buying contact at{" "}
                            <i
                                className="fa-solid fa-envelope"
                                style={{ margin: "0 5px" }}
                            ></i>{" "}
                            info@dashbone.io
                        </marquee>
                    </div>

                    <div className="banner-section">
                        <div class='row'>
                            <div class='col-md-7 align-self-center'>
                                <div className="content-wrapper">
                                    <h1>Exciting news !<br /> <span>Dashbone AI</span> is now live! </h1>
                                    <h3>Gain access to free <span>Dashbone AI</span> tools by logging in the <span>MetaMask</span>. <br></br> Don't wait, connect your wallet now! </h3>
                                </div>
                            </div>
                            <div class="col-md-5 align-self-center">
                                <div class="img-wrapper">
                                    <img src={BannerImg} alt="BannerImg" className='BannerImg' />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="hero-subheading">
                                <div className="coll">
                                    <h5 className="uk-text-gradient">0.008$</h5>
                                    <p>1% - 5%</p>
                                </div>
                                <div className="coll">
                                    <h5 className="uk-text-gradient">0.009$</h5>
                                    <p>6% - 10%</p>
                                </div>
                                <div className="coll">
                                    <h5 className="uk-text-gradient">0.01$</h5>
                                    <p>11% - 15%</p>
                                </div>
                                <img
                                    className="anim_pic1"
                                    src={require("../images/circle-01.png")}
                                    style={{ width: "16px" }}
                                />
                                <img
                                    className="anim_pic2"
                                    src={require("../images/ethereum-01.png")}
                                    style={{ width: "44px" }}
                                />
                            </div>

                            <h2 className="uk-h1  uk-heading-d4@m uk-margin-small uk-margin@m">
                                PRE-SALE has been started.
                            </h2>

                            {/* <div className="countdown">
                                <main className="main page__main">
                                    <CountdownTimer />
                                </main>
                            </div> */}
                            <div className="main_description">
                                {/* <h5 className="main_date">01-April-2023</h5> */}
                                <p>
                                    DBN will be the next trend in crypto. For Private Buying
                                    Contact Us
                                    <br />
                                </p>
                                <a href="mailto:info@dashbone.io">
                                    <i className="fa-solid fa-envelope"></i>info@dashbone.io
                                </a>
                                <div>
                                    <a
                                        href={require("../images/dashbone-whitepaper.pdf")}
                                        target="_blank"
                                    >
                                        <button className="btn main-btn">
                                            <i className="fa-solid fa-file"></i> Whitepaper
                                        </button>
                                    </a>
                                    {
                                        error == "Network" ?
                                            <button onClick={async () => {
                                                await switchNetwork(supportedChainIds[0]);
                                                connect();

                                            }} className="btn main-btn">
                                                Switch To BNB
                                            </button>
                                            :
                                            <button onClick={() => {
                                                web3Provider ?
                                                    dispatch(getRewatdAsyncThunk({ networkID: network?.chainId, provider: web3Provider, address }))
                                                    :
                                                    connect()

                                            }} className="btn main-btn">
                                                {web3Provider ? 'Get Free DBN' : 'Connect & Get DBN'}
                                            </button>

                                    }

                                    <a
                                        target="_blank"
                                        href="https://tokpie.zendesk.com/hc/en-us/articles/18320667733649#h_01G4XD4R5FSMWDTYNHTM4Z449B"
                                    >
                                        <button className="btn main-btn">Credit card</button>
                                    </a>

                                    <a
                                        target="_blank"
                                        href="https://amo.to/K/I34PFW/I2VOII"
                                    >
                                        <button className="btn main-btn">Applepay</button>
                                    </a>
                                    {address && <span
                                        onClick={addToWhiteList}
                                    >
                                        <button className="btn main-btn">
                                            {isLoading ? <div style={{ width: '110px' }}> <Spinner animation="border" variant="primary" /></div> : 'Add to Whitelist'}
                                        </button>
                                    </span>}
                                </div>
                            </div>

                            {/* <img className='anim_pic5' src={require('../images/circle-03.png')} style={{ width: "24px" }} /> */}
                        </div>

                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className='main_rightImg'>
                                <img src={require('../images/mainPic_right.png')} />
                                <img className='anim_pic3' src={require('../images/x-01.png')} style={{ width: "28px" }} />
                                <img className='anim_pic4' src={require('../images/bitcoin-01.png')} style={{ width: "44px" }} />
                            </div>
                            {/* <div className="main-card">
                                <div className="phase">


                                    <p className="phase-content">Dashbone=0.008$</p>
                                </div>
                                <div className="progress-bar">
                                    <ProgressBar now={10} />
                                </div>
                                <div className="dollar-prices">
                                    <div>
                                        <p>$21,482.18</p>
                                    </div>
                                    <div>
                                        <p>$2,000,000</p>
                                    </div>
                                </div>
                                <hr />
                                <div className="eth">
                                    <div className="row btn-box">
                                        <div className="col-lg-3 bt-type">
                                            <div className="button-type">
                                                <div>
                                                    <img
                                                        className="diamonds"
                                                        src={require("../images/eth.png")}
                                                    />
                                                </div>
                                                <div>
                                                    <p>ETH</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 bt-type">
                                            <div className="button-type">
                                                <div>
                                                    <img
                                                        className="diamondd"
                                                        src={require("../images/BNB.png")}
                                                    />
                                                </div>
                                                <div>
                                                    <p>BNB</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 bt-type">
                                            <div className="button-type">
                                                <div>
                                                    <img
                                                        className="diamondss"
                                                        src={require("../images/usdt.png")}
                                                    />
                                                </div>
                                                <div>
                                                    <p>USDT</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="feilds">
                                    <p>
                                        Amount in <span>USDT </span>you pay
                                    </p>
                                    <div>
                                        <InputGroup className="mb-3">
                                            <Form.Control
                                                placeholder=""
                                                aria-label="Recipient's username"
                                                aria-describedby="basic-addon2"
                                            />
                                            <InputGroup.Text id="basic-addon2">
                                                <img className="diamond" src={require("../images/usdt.png")} />
                                            </InputGroup.Text>
                                        </InputGroup>
                                    </div>
                                </div>
                                <div className="feilds">
                                    <p>
                                        Amount in <span>Dashbone </span>you receive
                                    </p>
                                    <div>
                                        <InputGroup className="mb-3">
                                            <Form.Control
                                                placeholder=""
                                                aria-label="Recipient's username"
                                                aria-describedby="basic-addon2"
                                            />
                                            <InputGroup.Text id="basic-addon2">
                                                <img className="diamond" src={require("../images/usdt.png")} />
                                            </InputGroup.Text>
                                        </InputGroup>
                                    </div>
                                </div>
                                <div className="big-button">
                                    <Button variant="secondary" size="lg">
                                        Content Wallet
                                    </Button>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>

                <div className="section_01">
                    <div className="row">
                        <div className="col-lg-4 col-md-4 col-sm-12">
                            <h6>1 Billion</h6>
                            <span>Maximum Supply</span>
                            <img
                                className="anim_pic6"
                                src={require("../images/bitcoin-01.png")}
                                style={{ width: "32px" }}
                            />
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12">
                            <h6>15%</h6>
                            <span>Max Supply</span>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12">
                            <h6>0.008$</h6>
                            <span>1 Dashbone</span>
                            <img
                                className="anim_pic7"
                                src={require("../images/ethereum-01.png")}
                                style={{ width: "24px" }}
                            />
                        </div>
                    </div>
                </div>

                <div className="section_02">
                    <div className="row">
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="sec02_leftImg">
                                <img
                                    src={require("../images/features-04.png")}
                                    style={{ width: "100%" }}
                                />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="sec02_rightText">
                                <span>Introduction</span>
                                <h3>What is Dashbone?</h3>
                                <p>
                                    Dashbone is a multi-platform investment portfolio project
                                    based on blockchain technology, Web 3.0, digital ownership,
                                    crypto, NFTs, and more. The project, envisioned by an
                                    experienced team of developers, is being executed by a global
                                    company with over 20 years of experience in diverse business
                                    domains helping clients achieve multiple parallel passive
                                    income streams and profitability goals.
                                </p>
                                <p>
                                    Dashbone works with smart contracts and is an easy way to
                                    generate lifetime income. Our expertise includes brokerage on
                                    wall street, portfolio investments, forex trading, real
                                    estate, and more recently crypto-currencies. We set high
                                    standards for ourselves and always aim to deliver more than
                                    what is expected.
                                </p>
                                <p>
                                    <strong>
                                        Like BITCOIN, DASHBONE is a once-in-a-lifetime opportunity
                                        that you would not want to miss.
                                    </strong>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section_03">
                    <h2>Why choose us?</h2>
                    <div className="row">
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="card_01">
                                <span>01.</span>
                                <div className="card_content">
                                    <h3>Super Simple & Adaptive</h3>
                                    <p>
                                        We do the research and adapt with the experience & technical
                                        knowledge every investor craves.
                                    </p>
                                </div>
                                <img
                                    className="anim_pic8"
                                    src={require("../images/circle-01.png")}
                                    style={{ width: "16px" }}
                                />
                                <img
                                    className="anim_pic9"
                                    src={require("../images/x-01.png")}
                                    style={{ width: "28px" }}
                                />
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="card_02">
                                <span>02.</span>
                                <div className="card_content">
                                    <h3>Stable Passive Income</h3>
                                    <p>
                                        Let us take care of your income goals with our Intuitive &
                                        secure investment plans..
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="card_01">
                                <span>03.</span>
                                <div className="card_content">
                                    <h3>Our Ecosystem</h3>
                                    <p>
                                        Stash, stake, bond, and trade digital assets using our
                                        token, dynamic NFTs stored to earn more.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="card_02">
                                <span>04.</span>
                                <div className="card_content">
                                    <h3>Best Payout System</h3>
                                    <p>
                                        Deposit and Withdraw funds to your personal wallet anytime,
                                        anywhere..
                                    </p>
                                </div>
                                <img
                                    className="anim_pic10"
                                    src={require("../images/circle-02.png")}
                                    style={{ width: "24px" }}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section_04">
                    <div className="row">
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="sec04_leftText">
                                <span>NFT's</span>
                                <h3>Non-Fungible Tokens (NFT's)</h3>

                                <p>
                                    <b> Non-Fungible Tokens (NFT's)</b> are cryptographic unique
                                    assets like a piece of art, digital content, or media on a
                                    blockchain with unique identification codes and metadata that
                                    distinguish them from each other. NFTs are unique blockchain
                                    tokens that serve as a digital certificates of ownership and
                                    authenticity for both virtual and real-world assets.
                                </p>
                                <p>
                                    The current popularity of the non-fungible token (NFTs) market
                                    can be considered one of the most notable public successes of
                                    the blockchain technology.
                                </p>

                                <div className="column_section">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                            <div className="section_01">
                                                <div className="icon">
                                                    <i className="fa-light fa-chart-tree-map"></i>
                                                </div>
                                                <div className="content">
                                                    1. Blockchain is a technology which is not only
                                                    dependent on crypto Currencies.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                            <div className="section_01">
                                                <div className="icon">
                                                    <i className="fa-light fa-bullseye-pointer"></i>
                                                </div>
                                                <div className="content">
                                                    2. NFT’s are not Currencies but assets and can be
                                                    transfers in Blockchain.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                            <div className="section_01">
                                                <div className="icon">
                                                    <i className="fa-light fa-bullseye-pointer"></i>
                                                </div>
                                                <div className="content">
                                                    3. NFT’s would be the future for the assets management
                                                    and transfers.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                            <div className="section_01">
                                                <div className="icon">
                                                    <i className="fa-light fa-bullseye-pointer"></i>
                                                </div>
                                                <div className="content">
                                                    4. Metaverse would be the next Generation Visual World
                                                    where Crypto Currencies and NFT’s will play a Big
                                                    Role.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="sec04_rightImg">
                                <img
                                    src={require("../images/features-02.png")}
                                    style={{ width: "100%" }}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section_05">
                    <div className="row">
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="section_01">
                                <img
                                    className="anim_pic11"
                                    src={require("../images/circle-01.png")}
                                    style={{ width: "16px" }}
                                />
                                <div className="sec01_cardImg">
                                    <img
                                        src={require("../images/icon-01.png")}
                                        style={{ width: "100%" }}
                                    />
                                </div>
                                <div className="sec01_content">
                                    <h3>Join our Community</h3>
                                    <p>
                                        Let us join hands and move forward as a growing & cultured
                                        community.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="section_01">
                                <div className="sec01_cardImg">
                                    <img
                                        src={require("../images/icon-03.png")}
                                        style={{ width: "100%" }}
                                    />
                                </div>
                                <div className="sec01_content">
                                    <h3>Deposit Funds</h3>
                                    <p>
                                        Deposit your funds securely to be a part of the biggest
                                        inevitable change.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="section_01">
                                <img
                                    className="anim_pic12"
                                    src={require("../images/x-01.png")}
                                    style={{ width: "28px" }}
                                />
                                <div className="sec01_cardImg">
                                    <img
                                        src={require("../images/icon-02.png")}
                                        style={{ width: "100%" }}
                                    />
                                </div>
                                <div className="sec01_content">
                                    <h3>Stake Automatically</h3>
                                    <p>
                                        Stake your purchased coins automatically & earn rewards
                                        passively.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="section_01">
                                <img
                                    className="anim_pic13"
                                    src={require("../images/circle-02.png")}
                                    style={{ width: "24px" }}
                                />
                                <div className="sec01_cardImg">
                                    <img
                                        src={require("../images/icon-04.png")}
                                        style={{ width: "100%" }}
                                    />
                                </div>
                                <div className="sec01_content">
                                    <h3>Referrals & Rewards</h3>
                                    <p>
                                        let’s help your friends join in to multiply & spread the
                                        financial returns.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section_04">
                    <div className="row">
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="sec04_leftText">
                                <span>PROBLEMS</span>
                                <h3>Problems and Drawbacks</h3>
                                <p>
                                    In the recent past blockchain industry faces many issues
                                    regarding regulations.
                                </p>

                                <div className="column_section">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                            <div className="section_01">
                                                <div className="icon">
                                                    <i className="fa-light fa-chart-tree-map"></i>
                                                </div>
                                                <div className="content">
                                                    People start investing in almost every projects by
                                                    just seeing the hypes
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                            <div className="section_01">
                                                <div className="icon">
                                                    <i className="fa-light fa-chart-tree-map"></i>
                                                </div>
                                                <div className="content">
                                                    Thousands of coins getting listed with marketing plans
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                            <div className="section_01">
                                                <div className="icon">
                                                    <i className="fa-light fa-bullseye-pointer"></i>
                                                </div>
                                                <div className="content">
                                                    Initial coin Offering process lose its confidence
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                            <div className="section_01">
                                                <div className="icon">
                                                    <i className="fa-light fa-chart-tree-map"></i>
                                                </div>
                                                <div className="content">
                                                    Group of investors start manipulating the projects
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                            <div className="section_01">
                                                <div className="icon">
                                                    <i className="fa-light fa-chart-tree-map"></i>
                                                </div>
                                                <div className="content">
                                                    Project Owners start doing business in manipulation
                                                    with the supply and demand
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                            <div className="section_01">
                                                <div className="icon">
                                                    <i className="fa-light fa-chart-tree-map"></i>
                                                </div>
                                                <div className="content">
                                                    As the market is highly volatile and people start
                                                    losing money
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="sec04_rightImg">
                                <img
                                    src={require("../images/features-03.png")}
                                    style={{ width: "100%" }}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section_06">
                    <h2>Dashbone Roadmap</h2>

                    <div className="section_container">
                        <ul className="timeline">
                            {/* <!-- Item 1 --> */}
                            <li>
                                <div className="direction-r">
                                    <div className="flag-wrapper">
                                        <span className="flag">
                                            <div className="flag_title">
                                                <h4></h4>
                                                <span className="flag_num">
                                                    <span>1</span>.
                                                </span>
                                            </div>
                                            <h3>1st Quarter 2023</h3>
                                            <p>
                                                In its First Phase Dashbone have plan about to Convey
                                                its message to the community that is the Future and
                                                Dashbone cannot win it all alone without the Community
                                                Building.
                                                <br />
                                                For that Dashbone Launches its First Part of the Project
                                                to attract the Community by give and take Plan.
                                                <br />
                                                <br />
                                                Dashbone will be Launch in its First Phase where
                                                Investors get the benefit and also work as Influencers
                                                to Build the Community.
                                                <br />
                                                <br />
                                                In this Phase 1.
                                                <br />
                                                1. Investors will get the Dashbone token on best price.
                                                <br />
                                                2. Tokens will be Locked for 1000 Days
                                                <br />
                                                3. Investors will get the a Beneficiary plan Too.
                                                <br />
                                                4. Marketers will be also rewarded in a beneficiary plan
                                                <br />
                                                5. To Build Up the community Dashbone team and Community
                                                will work in different continents i.e Asia , Europe ,
                                                Africa and America.
                                                <br />
                                                6. Target is to Build up Community of 50k members and
                                                Trust of the Community.
                                            </p>
                                        </span>
                                        {/* <span className="time-wrapper"><span className="time">2013 - present</span></span> */}
                                    </div>
                                    {/* <div className="desc">My current employment. Way better than the position before!</div> */}
                                </div>
                            </li>

                            {/* <!-- Item 2 --> */}
                            <li>
                                <div className="direction-l">
                                    <div className="flag-wrapper">
                                        <span className="flag">
                                            <div className="flag_title">
                                                <h4></h4>
                                                <span className="flag_num">
                                                    <span>2</span>.
                                                </span>
                                            </div>
                                            <h3>2nd Quarter 2023</h3>
                                            <p>
                                                1. Engage more Community up to 200K on the basis of
                                                technicalities of the Project.
                                                <br />
                                                2. Connectivity and Liquidity pool on pancake swap
                                                finance to engage more and more community.
                                                <br />
                                                3. Listing to IFO ( Initial Farm Offering ) on panckake
                                                swap
                                                <br />
                                                4. Get ERC20 Contract address and connectivity with
                                                Uniswap V3.
                                                <br />
                                                5. Get TRC20 Contract Address and Connectivity with
                                                Multiple Defi Platforms
                                                <br />
                                                6. Integration and Minting of NFT’s which will be
                                                awarded to Community on reward basis.
                                            </p>
                                        </span>
                                        {/* <span className="time-wrapper"><span className="time">2011 - 2013</span></span> */}
                                    </div>
                                    {/* <div className="desc">My first employer. All the stuff I've learned and projects I've been working on.</div> */}
                                </div>
                            </li>

                            {/* Item-3rd */}

                            <li>
                                <div className="direction-r">
                                    <div className="flag-wrapper">
                                        <span className="flag">
                                            <div className="flag_title">
                                                <h4></h4>
                                                <span className="flag_num">
                                                    <span>3</span>.
                                                </span>
                                            </div>
                                            <h3>3rd Quarter 2023</h3>
                                            <p>
                                                Dashbone is hoping the ideal case for the preparation
                                                and launching for the Registrations and Regulations in
                                                Multiple Continents Preferably it depends on the future
                                                condition on of Regulations in USA and and UAE.
                                                <br />
                                            </p>
                                            <p>
                                                1. Get the Liquidity in PancakeSwap Defi
                                                <br />
                                                2. Apply for the Liquidity Providers on the basis of
                                                community and Volume.
                                                <br />
                                                3. Dashbone Would also be able to get the Liquidity by
                                                self on any of the better Defi of that Time for sure on
                                                the basis of its circulating Supply..
                                                <br />
                                                4. Dashbone will Circulate its 40% of Supply in this
                                                Phase on Different Defi. Total Supply, circulating
                                                supply and Staked Supply Will Play a part first time in
                                                this Phase to Control the Price.
                                                <br />
                                            </p>
                                        </span>
                                        {/* <span className="time-wrapper"><span className="time">2013 - present</span></span> */}
                                    </div>
                                    {/* <div className="desc">My current employment. Way better than the position before!</div> */}
                                </div>
                            </li>

                            {/* Item-4th */}

                            <li>
                                <div className="direction-l">
                                    <div className="flag-wrapper">
                                        <span className="flag">
                                            <div className="flag_title">
                                                <h4></h4>
                                                <span className="flag_num">
                                                    <span>4</span>.
                                                </span>
                                            </div>
                                            <h3>4th Quarter 2023</h3>
                                            <p>
                                                1. Development and launch of the Dashbone based on
                                                either a Real Estate asset-backed token or Software
                                                project equity tokens, as decided by the community
                                                through governance decisions.
                                                <br />
                                                2. Dashbone community shall also be a part of this with
                                                its total 15% of staked LDBN tokens.
                                                <br />
                                                3. 40% of the total supply, by now available as
                                                liquidity on the Defi spaces.
                                                <br />
                                                4. Feasibility and development of Dashbone’s native
                                                marketplace.
                                                <br />
                                            </p>
                                        </span>
                                        {/* <span className="time-wrapper"><span className="time">2011 - 2013</span></span> */}
                                    </div>
                                    {/* <div className="desc">My first employer. All the stuff I've learned and projects I've been working on.</div> */}
                                </div>
                            </li>

                            {/* Item-5th */}

                            <li>
                                <div className="direction-r">
                                    <div className="flag-wrapper">
                                        <span className="flag">
                                            <div className="flag_title">
                                                <h4></h4>
                                                <span className="flag_num">
                                                    <span>5</span>.
                                                </span>
                                            </div>
                                            <h3>1st Quarter 2024</h3>
                                            <p>
                                                1. NFT’s will be use in Eco System
                                                <br />
                                                2. Every investor will get 10 NFT’s which will be minted
                                                in this phase for the usability in Eco System.
                                                <br />
                                                3. Also our community which have staked tokens could
                                                also buy from the circulating supply but cannot sell for
                                                Price Protection as they are getting rewards as per
                                                promised.
                                                <br />
                                                4. ECO system will have the different projects related
                                                to Metaverse and gaming Community
                                                <br />
                                            </p>
                                        </span>
                                        {/* <span className="time-wrapper"><span className="time">2013 - present</span></span> */}
                                    </div>
                                    {/* <div className="desc">My current employment. Way better than the position before!</div> */}
                                </div>
                            </li>

                            {/* Item-6th */}

                            <li>
                                <div className="direction-l">
                                    <div className="flag-wrapper">
                                        <span className="flag">
                                            <div className="flag_title">
                                                <h4></h4>
                                                <span className="flag_num">
                                                    <span>6</span>.
                                                </span>
                                            </div>
                                            <h3>2nd Quarter 2024</h3>
                                            <p>
                                                In this Phase the full Ecosystem will be Launched Major
                                                Ecosystem components will be
                                                <br />
                                                1. Dashbone Own Dex Exchange
                                                <br />
                                                2. Dashbone own Centralized Stocks, Forex, Crypto.
                                                <br />
                                                3. Dashbone Weekly Bet Lucky Winner Rewards
                                                <br />
                                                4. Dashbone Metaverse Project
                                                <br />
                                                5. Dashbone Games
                                                <br />
                                                6. Dashbone E-commerce Stores
                                                <br />
                                                7. Dashbone Booking Engine
                                                <br />
                                                8. Dashbone Business Software
                                                <br />
                                                9. Dashbone Learning Management System.
                                                <br />
                                            </p>
                                        </span>
                                        {/* <span className="time-wrapper"><span className="time">2011 - 2013</span></span> */}
                                    </div>
                                    {/* <div className="desc">My first employer. All the stuff I've learned and projects I've been working on.</div> */}
                                </div>
                            </li>

                            <li>
                                <div className="direction-r">
                                    <div className="flag-wrapper">
                                        <span className="flag">
                                            <div className="flag_title">
                                                <h4></h4>
                                                <span className="flag_num">
                                                    <span>5</span>.
                                                </span>
                                            </div>
                                            <h3>3rd Quarter 2024</h3>
                                            <p>
                                                During Q3 2024 we plan to take big steps to provide more
                                                accessibility and attain further popularity. We plan to
                                                list Dashbone tokens on several of the most trustworthy
                                                Centralized exchanges like the following ones:0
                                            </p>
                                            <p>
                                                1. Binance
                                                <br />
                                                2. Kucoin
                                                <br />
                                                3. Poloneix
                                                <br />
                                                4. Bittrex
                                                <br />
                                                5. Bitfinix
                                                <br />
                                            </p>
                                        </span>
                                        {/* <span className="time-wrapper"><span className="time">2013 - present</span></span> */}
                                    </div>
                                    {/* <div className="desc">My current employment. Way better than the position before!</div> */}
                                </div>
                            </li>

                            {/* Item-6th */}

                            <li>
                                <div className="direction-l">
                                    <div className="flag-wrapper">
                                        <span className="flag">
                                            <div className="flag_title">
                                                <h4></h4>
                                                <span className="flag_num">
                                                    <span>6</span>.
                                                </span>
                                            </div>
                                            <h3>4th Quarter 2024</h3>
                                            <p>
                                                Developing mobile Apps for Fiat to Dashbone and
                                                Dashboneto Fiat exchange like a Bank.
                                            </p>
                                            <p>
                                                Dashbone shall offer lending and bonding schemes through
                                                its banking system.
                                            </p>
                                            <p>
                                                The project shall invest income in various identified
                                                appreciation markets to get better returns and provide
                                                further liquidity to the ecosystem.
                                            </p>
                                            <p>
                                                Dashbone will issue the Master and Visa Cards to its
                                                community for withdrawals directly in fiat.
                                            </p>
                                        </span>
                                        {/* <span className="time-wrapper"><span className="time">2011 - 2013</span></span> */}
                                    </div>
                                    {/* <div className="desc">My first employer. All the stuff I've learned and projects I've been working on.</div> */}
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="section_07">
                    <p>POWERED BY AMAZING INVESTORS:</p>
                    <div className="brands_bx">
                        <div className="brand_logo">
                            <img
                                src={require("../images/wallet-01.png")}
                                style={{ width: "100%" }}
                            />
                        </div>
                        <div className="brand_logo">
                            <img
                                src={require("../images/wallet-02.png")}
                                style={{ width: "100%" }}
                            />
                        </div>
                        <div className="brand_logo">
                            <img
                                src={require("../images/wallet-03.png")}
                                style={{ width: "100%" }}
                            />
                        </div>
                        <div className="brand_logo">
                            <img
                                src={require("../images/wallet-04.png")}
                                style={{ width: "100%" }}
                            />
                        </div>
                        <div className="brand_logo">
                            <img
                                src={require("../images/wallet-05.png")}
                                style={{ width: "100%" }}
                            />
                        </div>
                    </div>
                </div>

                <div className="section_08">
                    <h2>Our Team</h2>
                    <div className="row section_container">
                        <div className="col-lg-3 col-md-6">
                            <div className="team_mem_01">
                                <img
                                    src={require("../images/t1.png")}
                                    style={{ width: "100%" }}
                                />
                                <div className="mem_data">
                                    <h3>Muhammad Usman </h3>
                                    <span>Business Analyst
                                    </span>
                                    <a href="https://www.linkedin.com/in/muhammad-usman-2164a327/">
                                        <i className="fa-brands fa-linkedin"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="team_mem_01">
                                <img
                                    src={require("../images/t2.jpg")}
                                    style={{ width: "100%" }}
                                />
                                <div className="mem_data">
                                    <h3>Asad Iqbal</h3>
                                    <span>Blockhain Developer</span>
                                    <a href="https://www.linkedin.com/in/asad-iqbal-b60203209/">
                                        <i className="fa-brands fa-linkedin"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="team_mem_01">
                                <img
                                    src={require("../images/t3.png")}
                                    style={{ width: "100%" }}
                                />
                                <div className="mem_data">
                                    <h3>Muhammad Farhan </h3>
                                    <span>Social Media Manager</span>
                                    <a href="https://www.linkedin.com/in/muhammad-farhan-7561bb214/">
                                        <i className="fa-brands fa-linkedin"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="team_mem_01">
                                <img
                                    src={require("../images/t4.png")}
                                    style={{ width: "100%" }}
                                />
                                <div className="mem_data">
                                    <h3>Haider Adrees </h3>
                                    <span>Web Designer</span>
                                    <a href="https://www.linkedin.com/in/haider-adrees-6089681b5/">
                                        <i className="fa-brands fa-linkedin"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-lg-3">
                            <div className="team_mem_01">
                                <img
                                    src={require("../images/t5.png")}
                                    style={{ width: "100%" }}
                                />
                                <div className="mem_data">
                                    <h3>Faisal Sattar </h3>
                                    <span>NFT Designer</span>
                                    <a href="https://www.linkedin.com/in/faisal-sattar-090726207/">
                                        <i className="fa-brands fa-linkedin"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="team_mem_01">
                                <img
                                    src={require("../images/t6.png")}
                                    style={{ width: "100%" }}
                                />
                                <div className="mem_data">
                                    <h3>Adnan Tahir </h3>
                                    <span>QA Specialist</span>
                                    <a href="https://www.linkedin.com/in/adnan-tahir1/">
                                        <i className="fa-brands fa-linkedin"></i>
                                    </a>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>

                <div className="section_09">
                    <h2>
                        ANSWERED
                        <br />
                        <span>QUESTIONS</span>
                    </h2>
                    <div className="section_container">
                        <div className="row">
                            <div className="col-lg-6 col-md-12 col-sm-12">
                                {/* <div className="accordion " id="accordionPanelsStayOpenExample">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="panelsStayOpen-headingOne">
                                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                                                How can we help You?
                                            </button>
                                        </h2>
                                        <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingOne">
                                            <div className="accordion-body">
                                                Duis aute irure dolor in Do You have any questions? We strongly recommend that you start searching for the necessary information in the FAQ section.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="panelsStayOpen-headingTwo">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
                                                What is Dashbone company?
                                            </button>
                                        </h2>
                                        <div id="panelsStayOpen-collapseTwo" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingTwo">
                                            <div className="accordion-body">
                                                Dashbone platform is an international staking company. The activity of our company is aimed at the cryptocurrency trading, forex, stocks and providing staking services worldwide.                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="panelsStayOpen-headingThree">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                                                How to create an account?
                                            </button>
                                        </h2>
                                        <div id="panelsStayOpen-collapseThree" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree">
                                            <div className="accordion-body">
                                                The registration process on the website is quite simple. You need to fill out the fields of the registration form, which include full name, email address and password.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="panelsStayOpen-headingFour">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFour" aria-expanded="false" aria-controls="panelsStayOpen-collapsFour">
                                                Which payment method do you accept?
                                            </button>
                                        </h2>
                                        <div id="panelsStayOpen-collapseFour" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingFour">
                                            <div className="accordion-body">
                                                At the moment we work with PayPal, Wire Transfer, Bitcoin, Ethereum, Litecoin, Binance Coin.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="panelsStayOpen-headingFive">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFive" aria-expanded="false" aria-controls="panelsStayOpen-collapsFive">
                                                I want to restake the funds received, is it possible?
                                            </button>
                                        </h2>
                                        <div id="panelsStayOpen-collapseFive" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingFive">
                                            <div className="accordion-body">
                                                Of course. You have the right to restaking your profits again and again.                                            </div>
                                        </div>
                                    </div>
                                </div> */}

                                <div className="mt-4 mb-4">
                                    <div className="accordion" id="myAccordion">
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingTwo">
                                                <button
                                                    type="button"
                                                    className="accordion-button collapsed"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#collapseTwo"
                                                >
                                                    How can we help you?
                                                </button>
                                            </h2>
                                            <div
                                                id="collapseTwo"
                                                className="accordion-collapse collapse"
                                                data-bs-parent="#myAccordion"
                                            >
                                                <div className="card-body">
                                                    <p>
                                                        Do You have any questions? We strongly recommend
                                                        that you start searching for the necessary
                                                        information in the FAQ section.{" "}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingOne">
                                                <button
                                                    type="button"
                                                    className="accordion-button collapsed"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#collapseOne"
                                                >
                                                    What is Dashbone Company?
                                                </button>
                                            </h2>
                                            <div
                                                id="collapseOne"
                                                className="accordion-collapse collapse"
                                                data-bs-parent="#myAccordion"
                                            >
                                                <div className="card-body">
                                                    <p>
                                                        Dashbone platform is an international staking
                                                        company. The activity of our company is aimed at the
                                                        cryptocurrency trading, forex, stocks and providing
                                                        staking services worldwide.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingThree">
                                                <button
                                                    type="button"
                                                    className="accordion-button collapsed"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#collapseThree"
                                                >
                                                    How to create an account?
                                                </button>
                                            </h2>
                                            <div
                                                id="collapseThree"
                                                className="accordion-collapse collapse"
                                                data-bs-parent="#myAccordion"
                                            >
                                                <div className="card-body">
                                                    <p>
                                                        The registration process on the website is quite
                                                        simple. You need to fill out the fields of the
                                                        registration form, which include email address and
                                                        wallet address.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingFour">
                                                <button
                                                    type="button"
                                                    className="accordion-button collapsed"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#collapseFour"
                                                >
                                                    Which payment method do you accept?
                                                </button>
                                            </h2>
                                            <div
                                                id="collapseFour"
                                                className="accordion-collapse collapse"
                                                data-bs-parent="#myAccordion"
                                            >
                                                <div className="card-body">
                                                    <p>
                                                        At the moment we work with USDT, Bitcoin, Ethereum,
                                                        Litecoin, Binance Coin.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingFive">
                                                <button
                                                    type="button"
                                                    className="accordion-button collapsed"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#collapseFive"
                                                >
                                                    I want to restake the funds received, is it possible?
                                                </button>
                                            </h2>
                                            <div
                                                id="collapseFive"
                                                className="accordion-collapse collapse"
                                                data-bs-parent="#myAccordion"
                                            >
                                                <div className="card-body">
                                                    <p>
                                                        Of course. You have the right to restaking your
                                                        profits again and again.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-12 col-sm-12">
                                <div className="sec_09_img">
                                    <img
                                        src={require("../images/land3-cta.png")}
                                        style={{ width: "100%" }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section_contact">
                <h2>Contact</h2>
                <div className="section_container">
                    <div className="field_01">
                        <input
                            type="text"
                            placeholder="Name"
                            name="name"
                            id="email"
                            required
                        />
                        <input
                            type="text"
                            placeholder="Email"
                            name="email"
                            id="email"
                            required
                            style={{ marginLeft: "15px" }}
                        />
                    </div>
                    <div className="field_01">
                        <input
                            type="text"
                            placeholder="Subject"
                            required
                            style={{ marginTop: "15px" }}
                        />
                    </div>
                    <div className="field_01" style={{ marginTop: "15px" }}>
                        <textarea placeholder="Type your message"></textarea>
                    </div>

                    <button>Send message</button>
                </div>
            </div>

            <div className="footer_section">
                <div className="section_container">
                    <img src={require("../images/logo.png")} style={{ width: "250px" }} />
                    <p>
                        We make it easy to Discover, Invest and manage all your NFTs at one
                        place.
                    </p>
                    <div className="icons">
                        <a href="https://twitter.com/DashBone1" target="_blank">
                            <i className="fa-brands fa-twitter"></i>
                        </a>
                        <a href="https://discord.gg/KVpw6gjzPf" target="_blank">
                            <i className="fa-brands fa-discord"></i>
                        </a>
                        <a
                            href="https://www.instagram.com/dashboneofficial/"
                            target="_blank"
                        >
                            <i className="fa-brands fa-instagram"></i>
                        </a>

                        <a
                            href="https://www.linkedin.com/in/dash-bone-11b37b268/"
                            target="_blank"
                        >
                            <i className="fa-brands fa-linkedin-in"></i>
                        </a>
                        <a href="https://medium.com/@dashbone" target="_blank">
                            <i className="fa-brands fa-medium"></i>
                        </a>
                        <a href="https://www.pinterest.com.au/dashboneio/" target="_blank">
                            <i className="fa-brands fa-pinterest-p"></i>
                        </a>
                        <a href="https://www.quora.com/profile/Dash-Bone-2" target="_blank">
                            <i className="fa-brands fa-quora"></i>
                        </a>
                    </div>
                    <img
                        className="anim_pic14"
                        src={require("../images/circle-01.png")}
                        style={{ width: "16px" }}
                    />
                    <img
                        className="anim_pic15"
                        src={require("../images/ethereum-01.png")}
                        style={{ width: "32px" }}
                    />
                    <img
                        className="anim_pic16"
                        src={require("../images/x-01.png")}
                        style={{ width: "16px" }}
                    />
                </div>
            </div>

            <div className="footer_bottom">
                <div className="section_container">
                    <span>© 2023 Dashbone. All rights reserved.</span>
                    {/* <span>© 2022 Dashbone. All rights reserved.</span> */}
                </div>
                <img
                    className="anim_pic17"
                    src={require("../images/circle-03.png")}
                    style={{ width: "24px" }}
                />
            </div>
            {/* <Button variant="primary" onClick={handleShow}>
                Launch demo modal
            </Button>

            <Modal show={show} variant="dark" centered onHide={handleClose} size="lg">
                <Modal.Body>
                    <img className='icon' src={require('../images/white-icon.png')} style={{ width: "200px" }} />

                    <h2>Heading here</h2>
                    <p>
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
                    </p>
                </Modal.Body>
            </Modal> */}
        </div>
    );
}
