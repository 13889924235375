import React, { useState } from 'react'
import { Form } from 'react-bootstrap'
import { useDispatch } from 'react-redux';
import { updateAdminAsyncThunk } from '../../redux/pagesSlices/emolume_bank';
import CustomButton from '../customs/CustomButton';
import WalletAddress from './FormFields/WalletAddress';

export default function ChangeAdmin({ networkID, provider }) {

    const [validated, setValidated] = useState(false);
    const dispatch = useDispatch();
    const handleSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
        } else {
            console.log('values',
                form["0"]?.value,
                form["1"]?.value,
            )
            dispatch(updateAdminAsyncThunk({ account: form["0"]?.value, networkID, provider }));
        }
    };

    return (
        <>
            <h2>Change Admin</h2>
            <p>You can manage DBN by performing actions. All actions that you can perform are listed in sidebar</p>
            <hr />
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <div className='create-new-poll'>
                <div className='row'>
                <WalletAddress />
                    <div className='col-lg-12'>
                        <div className='inner-row'>
                            <CustomButton
                                title="Swap"
                                type="submit"
                                slice={'bank'}
                                action={"usdtToDBONAsyncThunk"}
                                variant="warning"
                                btnClass="buy-btn w-100"
                            />
                        </div>
                    </div>
                </div>
                </div>
            </Form>
        </>
    )
}
