import React, { useState } from 'react'
import { Form } from 'react-bootstrap'
import { useDispatch } from 'react-redux';
import { transferUSDTAsyncThunk } from '../../redux/pagesSlices/emolume_bank';
import CustomButton from '../customs/CustomButton';
import Amount from './FormFields/Amount';
import WalletAddress from './FormFields/WalletAddress';

export default function TransferUSDT({ networkID, provider }) {

    const [validated, setValidated] = useState(false);
    const dispatch = useDispatch();
    const handleSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
        } else {
            console.log('values',
                form["0"]?.value,
                form["1"]?.value,
            )
            dispatch(transferUSDTAsyncThunk({ address: form["0"]?.value, amount: form["1"]?.value, networkID, provider }));
        }
    };

    return (
        <>
            <h2>Transfer USDT to User</h2>
            <p>You can widthdraw your Contract balance from here.</p>
            <hr />
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <div className='create-new-poll'>
                <div className='row'>
                    <WalletAddress />
                    <Amount />
                    <div className='col-lg-12'>
                        <div className='inner-row'>
                            <CustomButton
                                title="Transfer"
                                type="submit"
                                slice={'bank'}
                                action={"usdtToDBONAsyncThunk"}
                                variant="warning"
                                btnClass="buy-btn w-100"
                            />
                        </div>

                    </div>
                </div>
                </div>
            </Form>
        </>
    )
}
