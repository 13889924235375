import React from 'react'
import { Form } from 'react-bootstrap'

export default function TextField({title,ph}) {
    return (
        <div className='col-lg-6'>
            <Form.Group className='form-group' >
                <label>{title??"Enter Value"}</label>
                <Form.Control
                    type="test"
                    name="Proposal Text"
                    required
                    placeholder={ph ?? "Tnter Text..."}
                    aria-label="Proposal Text..."
                    aria-describedby="basic-addon2"
                />
                <Form.Control.Feedback type="invalid">
                    Please provide a valid Text.
                </Form.Control.Feedback>
                <Form.Control.Feedback type="valid">
                    Looks good!
                </Form.Control.Feedback>
            </Form.Group>
        </div>
    )
}