import React, { useState } from 'react'
import { Form } from 'react-bootstrap'
import { useDispatch } from 'react-redux';
import { subimtVoteProposalAsyncThunk } from '../../redux/pagesSlices/emolume_bank';
import CustomButton from '../customs/CustomButton';
import TextField from './FormFields/TextField'
export default function Index({ networkID, provider }) {

    const [validated, setValidated] = useState(false);
    const dispatch = useDispatch();

    const handleSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
        } else {
            console.log('values',
                form["0"]?.value,
            )
            dispatch(subimtVoteProposalAsyncThunk({
                proposal: form["0"]?.value,
                // checked,
                networkID, provider
            }));
        }
    };

    return (
        <>
            <h2>Submit Proposal</h2>
            <p>You can submit a proposal and get voted by public</p>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <div className='create-new-poll'>
                <div className='row'>
                    <TextField title={"Enter proposal text"} ph={"Proposal text..."} />
                    <div className='col-lg-12'>
                        <div className='inner-row'>
                            <CustomButton
                                title="Submit"
                                type="submit"
                                slice={'bank'}
                                action={"subimtVoteProposalAsyncThunk"}
                                variant="warning"
                                btnClass="buy-btn w-100"
                            />
                        </div>
                    </div>
                </div>
                </div>
            </Form>
        </>
    )
}
