import { ethers } from 'ethers';
import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { AiFillCloseCircle } from 'react-icons/ai'
import { useDispatch, useSelector } from 'react-redux';
import { getAddressesAndGetBalanceAsyncThunk, mintAsyncThunk } from '../../redux/pagesSlices/emolume_bank';
import CustomButton from '../customs/CustomButton';
import WalletAddress from './FormFields/WalletAddress';

export default function UpdateWhitelistAddresses({ networkID, provider }) {
    const [selectedAddress, setSelectedAddress] = useState(0)
    const [validated, setValidated] = useState(false);
    const { minForAddresses } = useSelector(s => s.bank);
    const dispatch = useDispatch();
    const addresses = minForAddresses?.addresses ?? []
    const [checked, setChecked] = useState(true);



    const handleSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
        } else {
            dispatch(getAddressesAndGetBalanceAsyncThunk({ accounts: addresses, checked, networkID, provider }));
        }
    };
    useEffect(() => {
        dispatch(getAddressesAndGetBalanceAsyncThunk({ accounts: addresses, checked, networkID, provider }));
    }, [])

    return (
        <>
            <h2>Add Users Accounts in Whitelist at onces</h2>
            <p>You can update users balances using one transaction from here so he/she can widthdraw quickly</p>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <div className='contact-form'>
                    <div className='select-field'>
                        {addresses.map(({ address, balance }, index) => (
                            <Row key={index} className="position-relative">
                                <div className='create-new-poll'>
                                    <div className='row'>
                                        <h6>Address: {address}</h6>
                                        <h6>Balance: {balance} DBN</h6>
                                        {+balance < 50 && <CustomButton
                                            title="Mint 50"
                                            type="button"
                                            slice={'bank'}
                                            isLoading={address == selectedAddress}
                                            onClick={() => {
                                                setSelectedAddress(address)
                                                dispatch(mintAsyncThunk({
                                                    account: address,
                                                    amount: 50,
                                                    networkID,
                                                    provider,
                                                    callback: () => { dispatch(getAddressesAndGetBalanceAsyncThunk({ accounts: addresses, checked, networkID, provider })) }
                                                }))
                                            }}
                                            action={"mintAsyncThunk"}
                                            variant="warning"
                                            btnClass="buy-btn w-100"
                                        />}
                                    </div>
                                </div>
                            </Row>))}
                    </div>
                </div>
            </Form>
        </>
    )
}
