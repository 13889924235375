import React from 'react'
import { Button, Dropdown } from 'react-bootstrap'
import { supportedChainIds } from '../../constants'
import { useWeb3Context } from '../../context'
import { chianToName } from '../../hooks/globalHelpers'
import { switchNetwork } from '../../hooks/Web3CustomHooks'

const ConnectButton = ({ connect, error }) => {
  return connect ? (
    (error == "Network" ?
      <Dropdown>
        <Dropdown.Toggle variant="warning" className='main-btn' id="dropdown-basic">
          Switch Chain
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {supportedChainIds.map((chainId, index) => {
            return <Dropdown.Item key={index} onClick={async () => {
              await switchNetwork(chainId);
              connect();
            }}>{chianToName(chainId)}</Dropdown.Item>
          })
          }
        </Dropdown.Menu>
      </Dropdown>
      :
      <span onClick={connect} data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"><i className="fa-regular fa-wallet"></i></span>
      </span>
    )
  ) : (
    <button>Loading...</button>
  )
}
const DisconnectButton = ({ disconnect }) => {
  return disconnect ? (
    <button onClick={disconnect} className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"> <i className="fa-solid fa-right-from-bracket"></i></span>
    </button>
  ) : (
    <button>Loading...</button>
  )
}

export function Web3ButtonMobile() {
  const { web3Provider, connect, disconnect, error } = useWeb3Context()

  return web3Provider ? (
    <DisconnectButton disconnect={disconnect} />
  ) : (
    <ConnectButton connect={connect} error={error} />
  )
}
