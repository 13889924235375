import React, { useState } from 'react'
import { Form } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { updateMEMLPerUSDTAsyncThunk } from '../../redux/pagesSlices/emolume_bank';
import CustomButton from '../customs/CustomButton';
import Amount from './FormFields/Amount';

export default function UpdateMEMLPerUSDT({ networkID, provider }) {

    const { analytics } = useSelector(state => state.bank)
    const { melmPerUSDT } = analytics;
    const [validated, setValidated] = useState(false);
    const dispatch = useDispatch();
    const handleSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
        } else {
            dispatch(updateMEMLPerUSDTAsyncThunk({ amount: form["0"]?.value, networkID, provider }));
        }
    };

    return (
        <>
            <h2>Update LUSDT per USDT</h2>
            <p>Current Price is {melmPerUSDT} DBN per USDT</p>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <div className='create-new-poll'>
                    <div className='row'>
                        <Amount />
                        <div className='col-lg-12'>
                            <div className='inner-row'>
                                <CustomButton
                                    title="Submit"
                                    type="submit"
                                    slice={'bank'}
                                    action={"updateMEMLPerUSDTAsyncThunk"}
                                    variant="warning"
                                    btnClass="buy-btn w-100"
                                />
                            </div>

                        </div>
                    </div>
                </div>
            </Form>
        </>
    )
}
