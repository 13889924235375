let Methods = {};

Methods['Mint DBN'] = require('./mint').default;
Methods['Burn DBN'] = require('./burn').default;
Methods['freeze'] = require('./freeze').default;
Methods['grantContractAdmin'] = require('./grantContractAdmin').default;
Methods['grantTransferAdmin'] = require('./grantTransferAdmin').default;
Methods['mint'] = require('./mint').default;
Methods['removeContractAdmin'] = require('./removeContractAdmin').default;
Methods['removeLockUntil'] = require('./removeLockUntil').default;
Methods['removeTransferAdmin'] = require('./removeTransferAdmin').default;
Methods['setAddressPermissions'] = require('./setAddressPermissions').default;
Methods['setAllowGroupTransfer'] = require('./setAllowGroupTransfer').default;
Methods['setTransferGroup'] = require('./setTransferGroup').default;
Methods['upgradeTransferRules'] = require('./upgradeTransferRules').default;
Methods['Analytics'] = require('./default').default;
Methods['USDT TO DBN'] = require('./usdtToDBON').default;
Methods['DBN TO USDT'] = require('./dbnToUsdt').default;
Methods['Add Whitelist'] = require('./addWhitelist').default;
Methods['Remove Whitelist'] = require('./removeWhitelist').default;
Methods['Change Admin'] = require('./changeAdmin').default;
Methods['Transfer USDT'] = require('./transferUSDT').default;
Methods['Update Isuer Address in DBN'] = require('./updateBank').default;
Methods['Update DBN For Bank'] = require('./updateDBN').default;
Methods['Tuggle DBN Transfer'] = require('./tuggleDBONTransfer').default;
Methods['Freez Whitelist Transfer DBN'] = require('./FreezWhitelistTransferDBON').default;
Methods['Update Whitelist Addresses'] = require('./updateWhitelistAddresses').default;
Methods['Update DBN Per USDT'] = require('./updateMEMLPerUSDT').default;
Methods['Remove Ownership of DBN'] = require('./removeOwnershipOfDBON').default;
Methods['Transfer DBN'] = require('./TransferDBON').default;
Methods['Get Reward'] = require('./getReward').default;
Methods['Submit Vote Proposal'] = require('./subimtVoteProposal').default;
Methods['Vote'] = require('./vote').default;
Methods['Claim Development Team Rewards'] = require('./claimDevelopmentTeamRewards').default;
Methods['Start Dev Team Reward'] = require('./startDevTeamRewardClaim').default;
Methods['transferUSDTTest'] = require('./transferUSDTTest').default;
Methods['create Pool'] = require('./createPool').default;
Methods['Accept Public Proposal'] = require('./updateAcceptPublicProposal').default;
Methods['Update Reward Settings'] = require('./setRewardSettings').default;
Methods['Swap Bridge'] = require('./bridge').default;
Methods['Mint Reward'] = require('./mintRewards').default;



export default Methods