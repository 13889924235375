import React, { useState } from 'react'
import { Form } from 'react-bootstrap'
import { useDispatch } from 'react-redux';
import { addresses } from '../../constants';
import { updateDBONAddressAsyncThunk } from '../../redux/pagesSlices/emolume_bank';
import CustomButton from '../customs/CustomButton';
import WalletAddress from './FormFields/WalletAddress'
export default function UpdateBank({ networkID, provider }) {

    const [validated, setValidated] = useState(false);
    const dispatch = useDispatch();
    const handleSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
        } else {
            console.log('values',
                form["0"]?.value,
                form["1"]?.value,
            )
            dispatch(updateDBONAddressAsyncThunk({ account: form["0"]?.value, networkID, provider }));
        }
    };

    return (
        <>
            <h2>update DBN in Issuer</h2>
            <p>You can manage DBN by performing actions. All actions that you can perform are listed in sidebar</p>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <p>This is Bank Addres if you have added this in constant : {addresses[networkID]?.emolumeIssuerAddress} </p>
                <div className='create-new-poll'>
                    <div className='row'>
                        <WalletAddress />
                        <div className='col-lg-12'>
                            <div className='inner-row'>
                                <CustomButton
                                    title="Submit"
                                    type="submit"
                                    slice={'bank'}
                                    action={"updateDBONAddressAsyncThunk"}
                                    variant="warning"
                                    btnClass="buy-btn w-100"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Form>
        </>
    )
}
