import React, { useEffect, useState } from 'react'
import { Col, Form, ProgressBar, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { useWeb3Context } from '../../context';
import { appLoadDataAsyncThunk, closeProposalPollAsyncThunk, subimtVoteProposalAsyncThunk, voteAsyncThunk } from '../../redux/pagesSlices/emolume_bank';
import CustomButton from '../customs/CustomButton';
import CustomIconButton from '../customs/CustomIconButton';
import { AiOutlineCloseCircle } from 'react-icons/ai'

export default function Index({ networkID, provider }) {

    const [createCard, setCreateCard] = useState(false);
    const [validated, setValidated] = useState(false);
    const dispatch = useDispatch();
    const { address } = useWeb3Context()
    const { analytics } = useSelector(state => state.bank)
    const { getVoteProposal } = analytics;
    useEffect(() => {
        if (address) {
            dispatch(appLoadDataAsyncThunk({ networkID, provider, address }))
        }
        return () => { }
    }, [])

    return (
        <>
            <h2>Vote for Proposal</h2>
            <p>You can Vote for any proposal</p>

            <div className='row mb-3'>
                {getVoteProposal?.map((item, index) => (
                    <div className='col-lg-6'>
                        <div className='voting-card'>
                            <h5>Poll ID#: {index}</h5>
                            <p>{item?.name}</p>
                            <hr />
                            <div className='poll-title'>
                                <h6>Vote In Yes</h6>
                                <h6>{item?.voteCountInYes}</h6>
                            </div>
                            <ProgressBar variant="primary" now={item?.voteCountInYes} />
                            <br />
                            <div className='poll-title'>
                                <h6>Vote In No</h6>
                                <h6>{item?.voteCountInNo}</h6>
                            </div>
                            <ProgressBar variant="primary" now={item?.voteCountInNo} />
                            <div className='poll-btns'>
                                <h6>
                                <CustomButton
                                    title="Yes"
                                    type="submit"
                                    slice={'bank'}
                                    action={"voteAsyncThunk"}
                                    variant="warning"
                                    btnClass="btn main-btn"
                                    onClick={() => dispatch(voteAsyncThunk({ index, vote: true, networkID, provider, address }))}
                                />
                                </h6>
                                <CustomButton
                                    title="Close Pool"
                                    type="submit"
                                    slice={'bank'}
                                    action={"closeProposalPollAsyncThunk"}
                                    variant="warning"
                                    btnClass="btn main-btn"
                                    onClick={() => dispatch(closeProposalPollAsyncThunk({ index, networkID, provider, address }))}
                                />
                                <h6>
                                <CustomButton
                                    title="No"
                                    type="submit"
                                    slice={'bank'}
                                    action={"voteAsyncThunk"}
                                    variant="warning"
                                    btnClass="btn main-btn"
                                    onClick={() => dispatch(voteAsyncThunk({ index, vote: false, networkID, provider, address }))}
                                />
                                </h6>
                            </div>
                        </div>
                    </div>
                ))}
                {
                    createCard ?
                        <div className={`col-lg-6 render ${createCard ? 'show' : ''}`}>
                            <div className='create-new-poll'>
                                <Form.Group className='form-group' >
                                    <label>Poll Question?</label>
                                    <Form.Control
                                        type="text"
                                        required
                                        placeholder="Poll Question"
                                    />
                                    <small>You can rise a poll and other can vote to your poll.</small>
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a valid wallet.
                                    </Form.Control.Feedback>
                                    <Form.Control.Feedback type="valid">
                                        Looks good!
                                    </Form.Control.Feedback>

                                </Form.Group>

                                <button className='btn main-btn' onClick={() => setCreateCard(!createCard)}>Submit</button>

                            </div>
                        </div>
                        :
                        <div onClick={() => setCreateCard(!createCard)} className={`col-lg-6 render ${createCard ? '' : 'show'}`}>
                            <div className='create-poll-card'>
                                <i className="fa-solid fa-plus-large"></i>
                                <h4>Create New Poll</h4>
                            </div>
                        </div>

                }
                {!getVoteProposal.length && <h3>There is no Proposal but you can create one.</h3>}
            </div>
        </>
    )
}
