import { ethers } from 'ethers';
import React, { useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { AiFillCloseCircle } from 'react-icons/ai'
import { useDispatch } from 'react-redux';
import { updateWhitelistAddressesAsyncThunk } from '../../redux/pagesSlices/emolume_bank';
import CustomButton from '../customs/CustomButton';
import WalletAddress from './FormFields/WalletAddress';

export default function UpdateWhitelistAddresses({ networkID, provider }) {

    const [validated, setValidated] = useState(false);
    const dispatch = useDispatch();
    const [addresses, setAddresses] = useState(['']);
    const [checked, setChecked] = useState(true);



    const handleSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
        } else {
            dispatch(updateWhitelistAddressesAsyncThunk({ accounts: addresses, checked, networkID, provider }));
        }
    };

    return (
        <>
            <h2>Add Users Accounts in Whitelist at onces</h2>
            <p>You can update users balances using one transaction from here so he/she can widthdraw quickly</p>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <div className='contact-form'>
                    <div className='select-field'>
                        {addresses.map((sAddress, index) => (
                            <Row key={index} className="position-relative">
                                <div className='create-new-poll'>
                                    <div className='row'>

                                        <WalletAddress
                                            value={addresses[index]}
                                            onChange={(e) => setAddresses(addresses.map((el, i) => i == index ? e.target.value : el))}
                                        />
                                    </div>
                                </div>

                                {/* <Col 
                                md={2} sm={12}
                                > */}
                                    {
                                        addresses.length > 1 &&
                                        <div className="position-absolute top-0 end-0">
                                            <AiFillCloseCircle size={32} onClick={() => {
                                                setAddresses(addresses.filter((_, i) => i !== index))
                                            }


                                            } />
                                        </div>
                                    }
                                {/* </Col> */}
                            </Row>))}
                        <div onClick={() => {
                            setAddresses([...addresses, ""]);
                        }} className={`col-lg-12`}>
                            <div className='create-poll-card'>
                                <i className="fa-solid fa-plus-large"></i>
                                <h4>Add More Address</h4>
                            </div>
                        </div>
                        <Form.Group >
                            <div className="ps-2">
                                <div>
                                    <div className="form-check">
                                        {/* <WalletAddress
                                    value={addresses[index]}
                                    onChange={(e) => setAddresses(addresses.map((el, i) => i == index ? e.target.value : el))}
                                /> */}
                                        <input defaultChecked value={checked} onChange={() => setChecked(!checked)} type="checkbox" className="form-check-input" id="exampleCheck1" />
                                        <label className="form-check-label" htmlFor="exampleCheck1">Check to add in whitelist</label>
                                    </div>
                                </div>
                                <p className='mb-3 ml-3 mt-3'>{checked ? 'Addresses will be added to whitelist!' : 'Addresses will be removed from whitelist!'}
                                </p>
                            </div>
                        </Form.Group>

                    </div>
                    <div className='contact-us mt-5'>
                        <div className='inner-row'>
                            <CustomButton
                                title="Submit"
                                type="submit"
                                slice={'bank'}
                                action={"updateWhitelistAddressesAsyncThunk"}
                                variant="warning"
                                btnClass="buy-btn w-100"
                            />
                        </div>

                    </div>
                </div>
            </Form>
        </>
    )
}
