import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { appLoadDataAsyncThunk, updatesetRewardSettingsAsyncThunk } from '../../redux/pagesSlices/emolume_bank';
import CustomButton from '../customs/CustomButton';
import Amount from './FormFields/Amount';

export default function SetRewardSettings({ networkID, provider, address }) {
    const [validated, setValidated] = useState(false);
    const [addresses, setAddresses] = useState(['']);
    const [checked, setChecked] = useState(true);
    const { analytics } = useSelector(state => state.bank);
    const { isRewardAvailable, rewardAmount } = analytics;
    const dispatch = useDispatch();
    useEffect(() => {

        dispatch(appLoadDataAsyncThunk({ networkID, provider, address }))
        return () => {

        }
    }, [])
    const handleSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
        } else {
            dispatch(updatesetRewardSettingsAsyncThunk({ amount: form["0"]?.value, checked, networkID, provider, address }));
        }
    };

    return (
        <div className='method-component'>
            <h2>Update Reward settings {isRewardAvailable ? 'disable' : 'enable'} Public Reward</h2>
            <p>You can enable/disable public Reward</p>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <div className='contact-form'>
                    <div className='create-new-poll'>
                        <div className='row'>
                            <Amount defaultValue={rewardAmount} />
                        </div>
                        <div className='row'>
                            <Form.Group >
                                <div className="ps-2">
                                    <div>
                                        <div className="form-check">
                                            <input
                                                defaultChecked={isRewardAvailable}
                                                // value={checked} 
                                                onChange={() => setChecked(!checked)}
                                                type="checkbox" className="form-check-input" id="exampleCheck1" />
                                            <label className="form-check-label" htmlFor="exampleCheck1">Check to Disable Reward</label>
                                        </div>
                                    </div>
                                    {/* <p className='mb-3 ml-3 mt-3'>{checked ? 'Addresses will be added to whitelist!' : 'Addresses will be removed from whitelist!'}</p>*/}
                                </div>
                            </Form.Group>
                        </div>
                        <div className='inner-row'>
                            <CustomButton
                                title={isRewardAvailable ? 'Disable Reward' : 'Enable Reward'}
                                type="submit"
                                slice={'bank'}
                                action={"updatesetRewardSettingsAsyncThunk"}
                                variant="warning"
                                btnClass="buy-btn w-100"
                            // onClick={(e) => {
                            //     e.preventDefault();
                            //     dispatch(updatesetRewardSettingsAsyncThunk({ update: !isRewardAvailable, networkID, provider }))
                            // }
                            // }
                            />
                        </div>

                    </div>
                </div>
            </Form>
        </div>
    )
}
