import { Link, Carousel, Dropdown, Button, InputGroup, Accordion, FormControl, Row, Col, ProgressBar, Form, Navbar, Container, Nav, NavDropdown } from 'react-bootstrap';
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { appLoadDataAsyncThunk, getdbonPriceAsyncThunk, usdtToDBONAsyncThunk } from '../../redux/pagesSlices/emolume_bank';
import CustomButton from '../customs/CustomButton';
import Amount from './FormFields/Amount';
import { useEffect } from 'react';
import { toast } from 'react-toastify';

export default function Index({ networkID, provider, address }) {
    const loading = useSelector((state) => state?.bank?.loadings?.["getdbonPriceAsyncThunk"]);

    const [validated, setValidated] = useState(false);
    const [usdt, setUSDT] = useState(0);
    const [DBON, setDBON] = useState(0);
    const { analytics, dbonPrice, } = useSelector(state => state.bank);
    const { userTeather, dbonBalance, } = analytics;
    // console.clear()
    console.log("userTeather:", userTeather)
    console.log("asaddddddbonBalance:", dbonBalance)
    const dispatch = useDispatch();
    const handleSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
        } else {
            console.log('values',
                form["0"]?.value,
                form["1"]?.value,
            )
            dispatch(usdtToDBONAsyncThunk({ amount: form["0"]?.value, networkID, provider }));
        }
    };
    useEffect(() => {
        dispatch(getdbonPriceAsyncThunk({ amount: usdt, networkID, provider }))
        return () => { }
    }, [usdt])
    useEffect(() => {
        dispatch(appLoadDataAsyncThunk({ networkID, provider, address }))
        return () => { }
    }, [])


    return (
        <>
            <h2>USDT To DBN</h2>
            <p>You can Swap your Tether to DBN.</p>
            <hr />
            <div className='row' >
                <div className='col-lg-3' />

                <div className='col-lg-6'>
                    <div className='swiping'>
                        <div className='swip-card'>
                            <div className='heading2'>
                                <div className='name'>Swap</div>
                                {/* <div className='options'>
                                    <Dropdown >
                                        <Dropdown.Toggle variant="dark" id="dropdown-basic">
                                            <i className="fa-duotone fa-gear"></i>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu variant="dark">
                                            <Dropdown.Item href="#/action-1">
                                                Action
                                            </Dropdown.Item>
                                            <Dropdown.Item href="#/action-1">
                                                Action
                                            </Dropdown.Item>
                                            <Dropdown.Item href="#/action-1">
                                                Action
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div> */}
                            </div>
                            <div className='swip-box'>
                                <Dropdown>
                                    <Dropdown.Toggle variant="dark" id="dropdown-basic">
                                        <img src={require('../../images/coin.png')} />

                                        USDT
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu variant="dark">
                                        <Dropdown.Item href="#/action-1">
                                            <img src={require('../../images/coin.png')} />

                                            USDT
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                                <div className='field-row'>
                                    <div className='left'>
                                        <FormControl
                                            placeholder="0.00"
                                            aria-label="name"
                                            type='number'
                                            aria-describedby="name"
                                            required
                                            onChange={(e) => setUSDT(e.target.value)}
                                        />
                                    </div>
                                    <div className='right'>
                                        <h6>
                                            Balance: {userTeather.toFixed(2)}
                                        </h6>
                                    </div>
                                </div>
                            </div>
                            <div className='swiper-arrow'>
                                <div className='arrow'>
                                    <i className="fa-duotone fa-arrow-down-arrow-up"></i>
                                </div>
                            </div>
                            <div className='swip-box'>
                                <Dropdown >
                                    <Dropdown.Toggle variant="dark" id="dropdown-basic">
                                        <img src={require('../../images/coin.png')} />

                                        DBN
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu variant="dark">
                                        <Dropdown.Item href="#/action-1">
                                            <img src={require('../../images/coin.png')} />

                                            DBN
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                                <div className='field-row'>
                                    <div className='left'>
                                        <FormControl
                                            placeholder="0.00"
                                            aria-label="name"
                                            type='number'
                                            aria-describedby="name"
                                            required
                                            disabled
                                            value={dbonPrice}
                                        />
                                    </div>
                                    <div className='right'>
                                        <h6>
                                            {dbonBalance && `Balance: ${dbonBalance?.toFixed(2)}`}
                                        </h6>
                                    </div>
                                </div>
                            </div>
                            <CustomButton
                                onClick={() => {
                                    if ((+dbonBalance + +dbonPrice) < 25000 && usdt > 0) {
                                        dispatch(usdtToDBONAsyncThunk({ amount: usdt, address, networkID, provider }))
                                    } else {
                                        toast.error(usdt <= 0 ? "Please Enter USDT Amount" : "You can not swap more than 25000 DBN")
                                    }
                                }}
                                title="Swap"
                                type="submit"
                                slice={'bank'}
                                action={"usdtToDBONAsyncThunk"}
                                variant="warning"
                                btnClass="buy-btn w-100"
                            />
                            {/* <button onClick={()=>dispatch(usdtToDBONAsyncThunk({ amount: usdt, networkID, provider }))} className='btn main-btn swap-btn'>Swap</button> */}

                        </div>
                    </div>
                </div>
                <div className='col-lg-3' />
            </div>
            {/* <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <div className='create-new-poll'>
                    <div className='row'>
                        <Amount />
                        <div className='col-lg-12'>
                            <div className='inner-row'>
                                <CustomButton
                                    title="Swap"
                                    type="submit"
                                    slice={'bank'}
                                    action={"usdtToDBONAsyncThunk"}
                                    variant="warning"
                                    btnClass="buy-btn w-100"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Form> */}
        </>

    )
}
