import React from 'react'
import { Form } from 'react-bootstrap'

export default function WalletAddress({ title,ph,...props}) {
    return (
        <div className='col-lg-6'>
            <Form.Group className='form-group' >
                <label>{title??"Wallet Address"}</label>
                <Form.Control
                    {...props}
                    type="text"
                    required
                    pattern="^0x[a-fA-F0-9]{40}$"
                    placeholder={ph??"Enter Wallet Address"}
                    aria-label={ph??"Enter Wallet Address"}
                    aria-describedby="basic-addon2"
                />
                <Form.Control.Feedback type="invalid">
                    Please provide a valid wallet.
                </Form.Control.Feedback>
                <Form.Control.Feedback type="valid">
                    Looks good!
                </Form.Control.Feedback>
            </Form.Group>
        </div>
    )
}