import React from 'react'
import { Button, Dropdown } from 'react-bootstrap'
import { supportedChainIds } from '../../constants'
import { useWeb3Context } from '../../context/'
import { chianToName } from '../../hooks/globalHelpers'
import { switchNetwork } from '../../hooks/Web3CustomHooks'

const ConnectButton = ({ connect, error }) => {
  return connect ? (
    (error == "Network" ?
      <Dropdown>
        <Dropdown.Toggle variant="warning" className='main-btn' id="dropdown-basic">
          Switch Chain
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {supportedChainIds.map((chainId, index) => {
            return <Dropdown.Item key={index} onClick={async () => {
              await switchNetwork(chainId);
              connect();
            }}>{chianToName(chainId)}</Dropdown.Item>
          })
          }
        </Dropdown.Menu>
      </Dropdown>
      :
      <div onClick={connect} className="nav-link">
        <button className='btn main-btn'>Connect</button>
      </div>
    )
  ) : (
    <button>Loading...</button>
  )
}
const DisconnectButton = ({ disconnect }) => {
  return disconnect ? (
    <div onClick={disconnect} className='buttons'>
    <button className='btn main-btn'>Disconnect</button>
  </div>
  ) : (
    <button>Loading...</button>
  )
}

export function Web3Button() {
  const { web3Provider, connect, disconnect, error } = useWeb3Context()

  return web3Provider ? (
    <DisconnectButton disconnect={disconnect} />
  ) : (
    <ConnectButton connect={connect} error={error} />
  )
}
