import React from 'react'
import { Form } from 'react-bootstrap'

export default function Amount({defaultValue, title,ph}) {
    return (
        <div className='col-lg-6'>
            <Form.Group className='form-group' >
                <label>{title??"Enter Amount"}</label>
                <Form.Control
                    type="number"
                    name="amount"
                    defaultValue={defaultValue ?? 0}
                    required
                    pattern="^(?=.*\d).{8,}$"
                    placeholder={ph?? "amount..."}
                    aria-label="amount..."
                    aria-describedby="basic-addon2"
                />
                <Form.Control.Feedback type="invalid">
                    Please provide a valid Amount.
                </Form.Control.Feedback>
                <Form.Control.Feedback type="valid">
                    Looks good!
                </Form.Control.Feedback>
            </Form.Group>
        </div>
    )
}