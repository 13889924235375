import React, { useEffect } from 'react'
import { Form } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { appLoadDataAsyncThunk, getRewatdAsyncThunk } from '../../redux/pagesSlices/emolume_bank';
import CustomButton from '../customs/CustomButton';

export default function Index({ networkID, provider, address }) {
    const dispatch=useDispatch()
    return (
        <div className='method-component'>
            <h2>Get Reward</h2>
            <p>You can get free reward</p>
            <Form noValidate>
                <div className='contact-form'>
                    <div className='contact-us '>
                        <div className='inner-row'>
                            <CustomButton
                                title={'Get reward'}
                                type="submit"
                                slice={'bank'}
                                action={"getRewatdAsyncThunk"}
                                variant="warning"
                                btnClass="buy-btn w-100"
                                onClick={(e) => {
                                    e.preventDefault();
                                    dispatch(getRewatdAsyncThunk({ networkID, provider, address }))
                                }
                                }
                            />
                        </div>

                    </div>
                </div>
            </Form>
        </div>
    )
}
