import React, { useEffect, useState, } from 'react'
import { Carousel, Dropdown, Button, InputGroup, Accordion, FormControl, Row, Col, ProgressBar, Form, Navbar, Container, Nav, NavDropdown } from 'react-bootstrap';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { permissions } from '../constants';
import { useWeb3Context } from '../context'
import Methods from '../components/Methods';
// import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { appLoadDataAsyncThunk } from '../redux/pagesSlices/emolume_bank';
import { Web3Button } from '../components/web3UI/Web3Button';
import { NavLink, Link } from 'react-router-dom';

AOS.init();
export default function Index() {
    const { web3Provider, address, network, connect } = useWeb3Context()
    console.log('error', useWeb3Context())
    const dispatch = useDispatch();
    const { analytics } = useSelector(state => state.bank)
    const { isOwner, isManager } = analytics;
    const [selectedMethod, setSelectedMethod] = useState('Analytics')
    const Method = Methods[selectedMethod];
    useEffect(() => {
        if (address) {
            dispatch(appLoadDataAsyncThunk({ networkID: network?.chainId, provider: web3Provider, address }))
        }
        return () => {
        }
    }, [address])


    return (
        <div>
            <div className='dashboard-body'>
                <div className='container-fluid'>
                    <div className='dashboard-head'>
                        <div className='logo'>
                            <img className='main_logo' src={require('../images/logo.png')} style={{ width: "200px" }} />
                        </div>
                        <div className='buttons'>
                            {/* <NavLink> */}
                            <Link to={"/"}>
                                <a className="nav-link disabled" href="swap" tabIndex="-1" aria-disabled="true">
                                    <button className='btn main-btn'>Home</button>
                                </a>
                            </Link>
                            {/* </NavLink> */}
                            <Web3Button />
                        </div>
                    </div>

                    <div className='dashboard-main'>
                        {
                            !web3Provider ?
                                <div className='welcome-msg'>
                                    <Container>
                                        <Row>
                                            <Col xs={12} lg={6}>
                                                <div className='content-here'>
                                                    <h2>
                                                        WELCOME TO DASHBONE APP
                                                    </h2>
                                                    <p>
                                                        Block chain Technology has the potential to enable social and economic growth globally by offering easier.
                                                    </p>
                                                    <button onClick={connect} className='main-btn btn btn-warning'>Connect wallet</button>
                                                </div>
                                            </Col>
                                            <Col xs={12} lg={1}>
                                            </Col>
                                            <Col xs={12} lg={5}>
                                                <div className='content-here'>
                                                    <img
                                                        src={require('../images/features-02.png')}
                                                        alt={'logo'} />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                                :
                                <div className='row'>
                                    <div className='col-lg-3'>
                                        <div className='side-bar'>
                                            <div className='nav-link-btns'>
                                                {
                                                    permissions[isOwner ? 'admin' : isManager ? 'manager' : 'user']?.map((item, index) => (
                                                        <button
                                                            key={index}
                                                            selectedmethod={item == selectedMethod ? "true" : "false"}
                                                            // style={{ backgroundColor: item == selectedMethod ? "#8364e2" : "#131519" }}
                                                            className='btn nav-btn'
                                                            onClick={() => { setSelectedMethod(item); window.scrollTo(0, 0) }} >
                                                            <i className="fa-duotone fa-bullseye me-2"></i>
                                                            {"" + item}</button>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-9'>
                                        <div className='col-lg-12'>
                                            <div>
                                                {Method && <Method networkID={network?.chainId} provider={web3Provider} address={address} />}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}
