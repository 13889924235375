import React, { useEffect, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { addWhiteListAsyncThunk, getWhiteListedAsyncThunk, removeWhiteListAsyncThunk } from '../../redux/pagesSlices/emolume_bank';
import CustomButton from '../customs/CustomButton';
import { BiWalletAlt } from 'react-icons/bi'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import CustomIconButton from '../customs/CustomIconButton';
import WalletAddress from './FormFields/WalletAddress';
export default function AddWhitelist({ networkID, provider }) {
    const { analytics } = useSelector(state => state.bank)
    const { wlisted } = analytics;

    const [validated, setValidated] = useState(false);
    const dispatch = useDispatch();
    const handleSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
        } else {
            console.log('values',
                form["0"]?.value,
                form["1"]?.value,
            )
            dispatch(addWhiteListAsyncThunk({ account: form["0"]?.value, networkID, provider }));
        }
    };
    useEffect(() => {
        dispatch(getWhiteListedAsyncThunk({ networkID, provider }))

        return () => {

        }
    }, [])


    return (
        <>
            <h2>Add Whitelist</h2>
            <p>You can manage DBN by performing actions. All actions that you can perform are listed in sidebar</p>
            <div className='mb-3'>
                {wlisted?.map((item, index) => (
                    <div className='whitlist-row' key={index}>
                        <Row >
                            <Col md={2}>
                                <i className="fa-duotone fa-wallet me-2"></i>  {index + 1} :
                            </Col>
                            <Col md={8}>
                                {item}
                            </Col>
                            <Col onClick={() => dispatch(removeWhiteListAsyncThunk({ account: item, networkID, provider }))} md={2}>
                                <CustomIconButton
                                    Icon={AiOutlineCloseCircle}
                                    type="submit"
                                    slice={'bank'}
                                    action={"removeWhiteListAsyncThunk"}
                                    variant="warning"
                                />
                            </Col>
                        </Row>
                    </div>
                ))}
            </div>

            <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <div className='create-new-poll'>
                <div className='row'>
                    <WalletAddress />
                </div>
                <div className='contact-form'>
                    <div className='contact-us '>
                        <div className='inner-row'>
                            <CustomButton
                                title="Submit"
                                type="submit"
                                slice={'bank'}
                                action={"addWhiteListAsyncThunk"}
                                variant="warning"
                                btnClass="buy-btn w-100"
                            />
                        </div>

                    </div>
                </div>
                </div>
            </Form>
        </>
    )
}
