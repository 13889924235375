import { createSlice, createAsyncThunk, isAnyOf } from "@reduxjs/toolkit";
import { catchAsync, detectError, handleLoading, reduxToolKitCaseBuilder } from "../../helpers/detectError";
import { toast } from "react-toastify";
import EmolumeIssuerABI from '../../abi/DboneIssuer.sol/DboneIssuer.json'
import DBONABI from '../../abi/DBN.sol/DBN.json'
import USDTabi from '../../abi/USDT.sol/USDT.json'
import { ethers } from "ethers";
import { addresses } from "../../constants";
import axios from "axios";



const contractCallwait = async e => {
    console.log("constract call:", e)
    if (e.wait) {
        const result = await e.wait();
        console.log("result:", result)
        return result;
    } else {
        return e;
    }
}

// user Login With Credentials
export const appLoadDataAsyncThunk = createAsyncThunk(
    "bank/appLoadDataAsyncThunk",
    catchAsync(async ({ networkID, provider, address }, _) => {
        console.log(networkID)
        // if (!provider) {
        //     console.error("failed to connect to provider, please connect your wallet");
        //   }
        const signer = provider.getSigner();
        const emolumeIssuerContract = new ethers.Contract(
            addresses[networkID].emolumeIssuerAddress,
            EmolumeIssuerABI.abi,
            signer,
        );
        const dbonContract = new ethers.Contract(
            addresses[networkID].dbonAddress,
            DBONABI.abi,
            signer,
        );
        const usdtContract = new ethers.Contract(
            addresses[networkID].usdtAddress,
            USDTabi.abi,
            signer,
        );
        const LpContract = new ethers.Contract(
            "0xC6EE792051CC4124881Ebe06F520ACFf74D319e6",
            USDTabi.abi,
            signer,
        );

        console.log("dbonContract:", dbonContract)
        console.log("emolumeIssuerContract:", emolumeIssuerContract)
        console.log("usdtContract:", usdtContract)
        const [
            // teather call

            myReward,
            isOwner,
            isRewardAvailable,
            rewardAmount,
            // isManager,
            isWhitelisted,
            dbonPerUSDT,
            usdtContractAddresses,
            ldbnContractAddresses,
            dbonContractAddresses,
            getVoteProposal,
            claimRewardTime,
            // allowClaimDevelopmentReward,
            claimedDevelopmentTeamReward,
            // developmentTeamReward,
            dues,
            whiteListedAddressesCount,
            collectedUsdtInSwap,
            acceptPublicProposal,
            rewardMinted,
            // getUsdtWithDBONPoolAddress,

            //usdt call 
            tetherInContract,
            userTeather,
            // LpContractBalance,

            // dbon call
            dbonTeather,
            totalSupply,
            totalSupplyLimit,
            isTransferable,
            dbonBankAddress,
            dbonBalance,
            dbonOwner,
        ] = await Promise.all([

            // teather call
            emolumeIssuerContract.rewards(address),
            emolumeIssuerContract.owner(),
            emolumeIssuerContract.isRewardAvailable(),
            emolumeIssuerContract.rewardAmount(),
            // emolumeIssuerContract.isManager(),
            // changed
            dbonContract.isWhitelisted(address),
            emolumeIssuerContract.dbonPerUSDT(),
            emolumeIssuerContract.usdtContract(),
            emolumeIssuerContract.dbonContract(),
            emolumeIssuerContract.dbonContract(),
            emolumeIssuerContract.getVoteProposal(),
            emolumeIssuerContract.claimRewardTime(),
            // emolumeIssuerContract.allowClaimDevelopmentReward(),
            emolumeIssuerContract.claimedDevelopmentTeamReward(),
            // emolumeIssuerContract.developmentTeamReward(),
            emolumeIssuerContract.dues(address),
            // changed
            dbonContract.getWhiteListedAddressesCount(),
            emolumeIssuerContract.collectedUsdtInSwap(),
            emolumeIssuerContract.acceptPublicProposal(),
            emolumeIssuerContract.rewardMinted(),
            // emolumeIssuerContract.getUsdtWithDBONPoolAddress(),

            // usdt call
            usdtContract.balanceOf(addresses[networkID].emolumeIssuerAddress,),
            usdtContract.balanceOf(address),
            // LpContract.balanceOf("0xdEC05462d84dF10B48B87cA70A80973B7659305c"),

            // dbon call
            dbonContract.balanceOf(addresses[networkID].emolumeIssuerAddress),
            dbonContract.totalSupply(),
            dbonContract.totalSupplyLimit(),
            dbonContract.isTransferable(),
            dbonContract.getManager(),
            dbonContract.balanceOf(address),
            dbonContract.owner(),
        ])
        console.group("emolumeIssuerContract")
        console.log("myReward", myReward.toString())
        console.log("rewardAmount", rewardAmount.toString() / 10 ** 18)
        console.log("isOwner", isOwner)
        console.log("isRewardAvailable", isRewardAvailable)
        // console.log("isManager", isManager)
        console.log("isWhitelisted", isWhitelisted)
        console.log("tetherInContract", tetherInContract.toString() / 10 ** 18)
        console.log("userTeather", userTeather.toString() / 10 ** 18)
        console.log("dbonTeather", dbonTeather.toString() / 10 ** 18)
        console.log("claimedDevelopmentTeamReward", claimedDevelopmentTeamReward.toString())
        // console.log("developmentTeamReward", developmentTeamReward.toString() / 10 ** 18)
        console.log("dues", dues.toString() / 10 ** 18)
        console.log("whiteListedAddressesCount", whiteListedAddressesCount.toString())
        console.log("dbonPerUSDT", dbonPerUSDT.toString())
        console.log("totalSupply", totalSupply.toString() / 10 ** 18)
        console.log("totalSupplyLimit", totalSupplyLimit.toString() / 10 ** 18)
        console.log("dbonBalance", dbonBalance.toString() / 10 ** 18)
        console.log("rewardMinted", rewardMinted.toString() / 10 ** 18)
        console.log("isTransferable", isTransferable)
        console.log("dbonBankAddress", dbonBankAddress)
        console.log("getVoteProposal", getVoteProposal)
        console.log("getVoteProposal", getVoteProposal._proposals)
        console.log("getVoteProposal", getVoteProposal.limit.toString())
        console.log("dbonOwner", dbonOwner)
        console.log("claimRewardTime", claimRewardTime.toString())
        // console.log("allowClaimDevelopmentReward", allowClaimDevelopmentReward)
        console.log("collectedUsdtInSwap::::::::::::", collectedUsdtInSwap.toString() / 10 ** 18)
        console.log("acceptPublicProposal::::::::::::", acceptPublicProposal)
        // console.log("getUsdtWithDBONPoolAddress::::::::::::", getUsdtWithDBONPoolAddress)
        // console.log("LpContractBalance::::::::::::", LpContractBalance.toString()/10**18)
        console.groupEnd()
        return {
            usdtContractAddresses,
            isRewardAvailable,
            ldbnContractAddresses,
            dbonContractAddresses,
            acceptPublicProposal,
            claimRewardTime: +claimRewardTime.toString(),
            rewardMinted: +rewardMinted.toString() / 10 ** 18,
            rewardAmount: rewardAmount.toString() / 10 ** 18,
            myReward: myReward.toString() / 10 ** 18,
            tetherInContract: tetherInContract.toString() / 10 ** 18,
            collectedUsdtInSwap: collectedUsdtInSwap.toString() / 10 ** 18,
            userTeather: userTeather.toString() / 10 ** 18,
            whiteListedAddressesCount: whiteListedAddressesCount.toString(),
            claimedDevelopmentTeamReward: +claimedDevelopmentTeamReward.toString() / 10 ** 18,
            // developmentTeamReward: +developmentTeamReward.toString() / 10 ** 18,
            dues: +dues.toString() / 10 ** 18,
            totalSupplyLimit: totalSupplyLimit.toString() / 10 ** 18,
            totalSupply: totalSupply.toString() / 10 ** 18,
            dbonPerUSDT: +dbonPerUSDT.toString(),
            isOwner: isOwner.toLowerCase() == address.toLowerCase(),
            getVoteProposal: getVoteProposal._proposals.map(el => {
                return {
                    isPoleOpen: el?.isPoleOpen,
                    submittedBy: el?.submittedBy,
                    voteCountInYes: el?.voteCountInYes?.toString(),
                    voteCountInNo: el?.voteCountInNo?.toString(),
                    name: el?.name
                }
            }),
            // allowClaimDevelopmentReward,
            getVoteProposalLimit: +getVoteProposal.limit.toString(),
            // isManager,
            dbonOwner,
            isTransferable,
            dbonBankAddress,
            dbonBalance: dbonBalance.toString() / 10 ** 18,
        }
    })
);

// unpause
export const getBalanceOfErc20AsyncThunk = createAsyncThunk(
    "bank/getBalanceOfErc20AsyncThunk",
    catchAsync(async ({ address, tokenAddress, provider }, _) => {

        const signer = provider.getSigner();
        const tokenABI = [
            {
                "constant": true,
                "inputs": [{ "name": "_owner", "type": "address" }],
                "name": "balanceOf",
                "outputs": [{ "name": "balance", "type": "uint256" }],
                "payable": false,
                "stateMutability": "view",
                "type": "function"
            }
        ];
        const erc20Contract = new ethers.Contract(
            tokenAddress,
            tokenABI,
            signer,
        );
        console.log("address:::", address)
        console.log("tokenAddress:::", tokenAddress)
        const swapBalance = await erc20Contract.balanceOf(address);
        console.log("getBalanceOfErc20AsyncThunk:::::", swapBalance.toString())
        await contractCallwait(swapBalance)
        return {
            swapBalance: swapBalance.toString(),
        }
    })
);
// unpause
export const updateDepositFeeAsyncThunk = createAsyncThunk(
    "bank/updateDepositFeeAsyncThunk",
    catchAsync(async ({ amount, networkID, provider }, _) => {

        const signer = provider.getSigner();
        const dbonIssuer = new ethers.Contract(
            addresses[networkID].emolumeIssuerAddress,
            EmolumeIssuerABI.abi,
            signer,
        );
        console.log("dbonIssuer:", dbonIssuer)
        const contractCall = await dbonIssuer.updateDepositFee(ethers.utils.parseUnits(amount.toString(), 18));

        await contractCallwait(contractCall)

        return {
            contractCall,
        }
    })
);
// unpause
export const updateMinDepositAsyncThunk = createAsyncThunk(
    "bank/updateMinDepositAsyncThunk",
    catchAsync(async ({ amount, networkID, provider }, _) => {

        const signer = provider.getSigner();
        const dbonIssuer = new ethers.Contract(
            addresses[networkID].emolumeIssuerAddress,
            EmolumeIssuerABI.abi,
            signer,
        );
        console.log("dbonIssuer:", dbonIssuer)
        const contractCall = await dbonIssuer.updateMinDeposit(ethers.utils.parseUnits(amount.toString(), 18));

        await contractCallwait(contractCall)

        return {
            contractCall,
        }
    })
);
// unpause
export const updateMinWithdrawAsyncThunk = createAsyncThunk(
    "bank/updateMinWithdrawAsyncThunk",
    catchAsync(async ({ amount, networkID, provider }, _) => {

        const signer = provider.getSigner();
        const dbonIssuer = new ethers.Contract(
            addresses[networkID].emolumeIssuerAddress,
            EmolumeIssuerABI.abi,
            signer,
        );
        console.log("dbonIssuer:", dbonIssuer)
        const contractCall = await dbonIssuer.updateMinWithdraw(ethers.utils.parseUnits(amount.toString(), 18));

        await contractCallwait(contractCall)

        return {
            contractCall,
        }
    })
);

// unpause
export const updateMEMLPerUSDTAsyncThunk = createAsyncThunk(
    "bank/updateMEMLPerUSDTAsyncThunk",
    catchAsync(async ({ amount, networkID, provider }, _) => {

        const signer = provider.getSigner();
        const dbonIssuer = new ethers.Contract(
            addresses[networkID].emolumeIssuerAddress,
            EmolumeIssuerABI.abi,
            signer,
        );
        console.log("dbonIssuer:", dbonIssuer)
        const contractCall = await dbonIssuer.updateMEMLPerUSDT(ethers.utils.parseUnits(amount.toString(), 0));

        await contractCallwait(contractCall)

        return {
            contractCall,
        }
    })
);
// unpause
export const usdtToDBONAsyncThunk = createAsyncThunk(
    "bank/usdtToDBONAsyncThunk",
    catchAsync(async ({ amount, networkID, provider, address }, { dispatch }) => {
        const signer = provider.getSigner();
        // 
        const usdt = new ethers.Contract(
            addresses[networkID].usdtAddress,
            USDTabi.abi,
            signer,
        );
        const allownce = await usdt.approve(addresses[networkID].emolumeIssuerAddress, ethers.utils.parseUnits(amount.toString(), 18));
        console.log("allownce:", allownce)
        const allownceResult = await allownce.wait()
        console.log("allownceResult:", allownceResult)

        // 
        const dbonIssuer = new ethers.Contract(
            addresses[networkID].emolumeIssuerAddress,
            EmolumeIssuerABI.abi,
            signer,
        );
        console.log("dbonIssuer:", dbonIssuer)
        const contractCall = await dbonIssuer.usdtToDBON(ethers.utils.parseUnits(amount.toString(), 18));

        await contractCallwait(contractCall)
        dispatch(appLoadDataAsyncThunk({ networkID, provider, address }))

        return {
            contractCall,
        }
    })
);
// unpause
export const dbnToUsdtAsyncThunk = createAsyncThunk(
    "bank/dbnToUsdtAsyncThunk",
    catchAsync(async ({ amount, networkID, provider, address }, { dispatch }) => {
        const signer = provider.getSigner();
        // 
        const usdt = new ethers.Contract(
            addresses[networkID].usdtAddress,
            USDTabi.abi,
            signer,
        );
        // const allownce = await usdt.approve(addresses[networkID].emolumeIssuerAddress, ethers.utils.parseUnits(amount.toString(), 18));
        // console.log("allownce:", allownce)
        // const allownceResult = await allownce.wait()
        // console.log("allownceResult:", allownceResult)

        // 
        const dbonIssuer = new ethers.Contract(
            addresses[networkID].emolumeIssuerAddress,
            EmolumeIssuerABI.abi,
            signer,
        );
        console.log("dbonIssuer:", dbonIssuer)
        const contractCall = await dbonIssuer.dbnToUsdt(ethers.utils.parseUnits(amount.toString(), 18));

        await contractCallwait(contractCall)
        dispatch(appLoadDataAsyncThunk({ networkID, provider, address }))

        return {
            contractCall,
        }
    })
);
// unpause
export const getdbonPriceAsyncThunk = createAsyncThunk(
    "bank/getdbonPriceAsyncThunk",
    catchAsync(async ({ amount, networkID, provider }, _) => {

        const signer = provider.getSigner();
        const dbonIssuer = new ethers.Contract(
            addresses[networkID].emolumeIssuerAddress,
            EmolumeIssuerABI.abi,
            signer,
        );
        if (!(+amount > 0)) {
            return { dbonPrice: 0 }
        }
        console.log("dbonIssuer:", dbonIssuer)
        console.log("amount:", amount)
        const contractCall = await dbonIssuer.usdtToDBNPrice(ethers.utils.parseUnits(amount.toString(), 18));
        console.log("contractCall:", contractCall)
        console.log("usdt swap:", contractCall?.newEmlmPerUSDT?.toString())
        console.log("usdt swap:", contractCall?.tokens?.toString())

        // await contractCallwait(contractCall)

        return {
            dbonPrice: contractCall.tokens.toString() / 10 ** 18,
        }
    })
);
export const getdusdtPriceAsyncThunk = createAsyncThunk(
    "bank/getdusdtPriceAsyncThunk",
    catchAsync(async ({ amount, networkID, provider }, _) => {

        const signer = provider.getSigner();
        const dbonIssuer = new ethers.Contract(
            addresses[networkID].emolumeIssuerAddress,
            EmolumeIssuerABI.abi,
            signer,
        );
        if (!(+amount > 0)) {
            return { usdtPrice: 0 }
        }
        console.log("dbonIssuer:", dbonIssuer)
        console.log("amount:", amount)
        const contractCall = await dbonIssuer.dbnToUsdtPrice(ethers.utils.parseUnits(amount.toString(), 18));
        console.log("newEmlmPerUSDT:", contractCall?.newEmlmPerUSDT?.toString())
        console.log("usdt swap:", contractCall?.usdt?.toString())

        // await contractCallwait(contractCall)

        return {
            usdtPrice: contractCall.usdt.toString() / 10 ** 18,
        }
    })
);
// updateAdminAsyncThunk
export const updateAdminAsyncThunk = createAsyncThunk(
    "bank/updateAdminAsyncThunk",
    catchAsync(async ({ account, networkID, provider }, _) => {

        const signer = provider.getSigner();
        // 
        const dbonIssuer = new ethers.Contract(
            addresses[networkID].emolumeIssuerAddress,
            EmolumeIssuerABI.abi,
            signer,
        );
        console.log("dbonIssuer:", dbonIssuer)
        const contractCall = await dbonIssuer.transferOwnership(account);

        await contractCallwait(contractCall)

        return {
            contractCall,
        }
    })
);
// remove oawnership of dbon
export const renounceOwnershipEMLBankAsyncThunk = createAsyncThunk(
    "bank/renounceOwnershipEMLBankAsyncThunk",
    catchAsync(async ({ networkID, provider }, _) => {
        const signer = provider.getSigner();
        // 
        const dbonIssuer = new ethers.Contract(
            addresses[networkID].emolumeIssuerAddress,
            EmolumeIssuerABI.abi,
            signer,
        );
        console.log("dbonIssuer:", dbonIssuer)
        const contractCall = await dbonIssuer.renounceOwnership();
        await contractCallwait(contractCall)

        return {
            contractCall,
        }
    })
);

export const transferAsyncThunk = createAsyncThunk(
    "bank/transferAsyncThunk",
    catchAsync(async ({ networkID, provider, address, amount }, _) => {
        const signer = provider.getSigner();
        const dbonContract = new ethers.Contract(
            addresses[networkID].dbonAddress,
            DBONABI.abi,
            signer,
        );
        console.log("address:", address)
        console.log("amount:", amount)

        console.log("dbonContract:", dbonContract)
        const contractCall = await dbonContract.transfer(address, ethers.utils.parseUnits(amount.toString(), 18));

        await contractCallwait(contractCall)

        return {
            contractCall,
        }
    })
);
// pause and uppause
export const claimDevelopmentTeamRewardsAsyncThunk = createAsyncThunk(
    "bank/claimDevelopmentTeamRewardsAsyncThunk",
    catchAsync(async ({ update, networkID, provider }, _) => {
        const signer = provider.getSigner();
        // 
        const dbonIssuer = new ethers.Contract(
            addresses[networkID].emolumeIssuerAddress,
            EmolumeIssuerABI.abi,
            signer,
        );
        const contractCall = await dbonIssuer.claimDevelopmentTeamRewards();
        await contractCallwait(contractCall)
        return {
            contractCall,
        }
    })
);
// pause and uppause
export const getRewatdAsyncThunk = createAsyncThunk(
    "bank/getRewatdAsyncThunk",
    catchAsync(async ({ address, networkID, provider }, { dispatch }) => {
        const signer = provider.getSigner();
        const dbonIssuer = new ethers.Contract(
            addresses[networkID].emolumeIssuerAddress,
            EmolumeIssuerABI.abi,
            signer,
        );
        const contractCall = await dbonIssuer.getreward();
        await contractCallwait(contractCall)
        dispatch(appLoadDataAsyncThunk({ networkID, provider, address }))

        return {
            contractCall,
        }
    })
);
// pause and uppause
export const startDevTeamRewardClaimAsyncThunk = createAsyncThunk(
    "bank/startDevTeamRewardClaimAsyncThunk",
    catchAsync(async ({ networkID, provider, address }, { dispatch }) => {
        const signer = provider.getSigner();
        // 
        const dbonIssuer = new ethers.Contract(
            addresses[networkID].emolumeIssuerAddress,
            EmolumeIssuerABI.abi,
            signer,
        );
        console.log("dbonIssuer:", dbonIssuer)
        const contractCall = await dbonIssuer.startDevTeamRewardClaim();
        await contractCallwait(contractCall)
        dispatch(appLoadDataAsyncThunk({ networkID, provider, address }))
        return {
            contractCall,
        }
    })
);
// pause and uppause
export const updateAcceptPublicProposalAsyncThunk = createAsyncThunk(
    "bank/updateAcceptPublicProposalAsyncThunk",
    catchAsync(async ({ update, networkID, provider }, _) => {
        const signer = provider.getSigner();
        // 
        const dbonIssuer = new ethers.Contract(
            addresses[networkID].emolumeIssuerAddress,
            EmolumeIssuerABI.abi,
            signer,
        );
        console.log("dbonIssuer:", dbonIssuer)
        const contractCall = await dbonIssuer.updateAcceptPublicProposal(update);
        await contractCallwait(contractCall)
        return {
            contractCall,
        }
    })
);
export const updatesetRewardSettingsAsyncThunk = createAsyncThunk(
    "bank/updatesetRewardSettingsAsyncThunk",
    catchAsync(async ({ checked, amount, networkID, provider, address }, { dispatch }) => {
        const signer = provider.getSigner();
        // 
        const dbonIssuer = new ethers.Contract(
            addresses[networkID].emolumeIssuerAddress,
            EmolumeIssuerABI.abi,
            signer,
        );
        console.log("dbonIssuer:", dbonIssuer)
        const contractCall = await dbonIssuer.setRewardSettings(checked, ethers.utils.parseUnits(amount.toString(), 18));
        await contractCallwait(contractCall)
        dispatch(appLoadDataAsyncThunk({ networkID, provider, address }))

        return {
            contractCall,
        }
    })
);
// pause and uppause
export const tuggleTransferDBONAsyncThunk = createAsyncThunk(
    "bank/tuggleTransferDBONAsyncThunk",
    catchAsync(async ({ update, networkID, provider }, _) => {
        const signer = provider.getSigner();
        // 
        const dbonContract = new ethers.Contract(
            addresses[networkID].dbonAddress,
            DBONABI.abi,
            signer,
        );
        console.log("dbonContract:", dbonContract)
        const contractCall = await dbonContract[update ? 'pauseTransfer' : 'resumeTransfer']();
        await contractCallwait(contractCall)
        return {
            contractCall,
        }
    })
);
export const FreezWhitelistTransferDBONAsyncThunk = createAsyncThunk(
    "bank/FreezWhitelistTransferDBONAsyncThunk",
    catchAsync(async ({ update, networkID, provider }, _) => {
        const signer = provider.getSigner();
        // 
        const dbonContract = new ethers.Contract(
            addresses[networkID].dbonAddress,
            DBONABI.abi,
            signer,
        );
        console.log("dbonContract:", dbonContract)
        const contractCall = await dbonContract.updateIsFreezedWhiteListTransfer(true);
        await contractCallwait(contractCall)
        return {
            contractCall,
        }
    })
);

// updateBankAsyncThunk for DBON
export const updateBankAsyncThunk = createAsyncThunk(
    "bank/updateBankAsyncThunk",
    catchAsync(async ({ account, networkID, provider }, _) => {
        const signer = provider.getSigner();
        // 
        const dbonContract = new ethers.Contract(
            addresses[networkID].dbonAddress,
            DBONABI.abi,
            signer,
        );
        console.log("dbonContract:", dbonContract)
        const contractCall = await dbonContract.updateManager(account);
        await contractCallwait(contractCall)
        return {
            contractCall,
        }
    })
);
export const updateDBONAddressAsyncThunk = createAsyncThunk(
    "bank/updateDBONAddressAsyncThunk",
    catchAsync(async ({ account, networkID, provider }, _) => {
        const signer = provider.getSigner();
        // 
        const dbonContract = new ethers.Contract(
            addresses[networkID].dbonAddress,
            DBONABI.abi,
            signer,
        );
        const emolumeIssuerContract = new ethers.Contract(
            addresses[networkID].emolumeIssuerAddress,
            EmolumeIssuerABI.abi,
            signer,
        );
        console.log("dbonContract:", dbonContract)
        const contractCall = await emolumeIssuerContract.updateDBONAddress(account);
        await contractCallwait(contractCall)
        return {
            contractCall,
        }
    })
);
// unpause
export const addWhiteListAsyncThunk = createAsyncThunk(
    "bank/addWhiteListAsyncThunk",
    catchAsync(async ({ account, networkID, provider }, { dispatch }) => {
        const signer = provider.getSigner();
        const dbonContract = new ethers.Contract(
            addresses[networkID].dbonAddress,
            DBONABI.abi,
            signer,
        );
        console.log("dbonContract:", dbonContract)
        const contractCall = await dbonContract.updateWhiteList(account, true);
        dispatch(getWhiteListedAsyncThunk({ networkID, provider }))
        await contractCallwait(contractCall)
        return {
            contractCall,
        }
    })
);
// unpause
export const getWhiteListedAsyncThunk = createAsyncThunk(
    "bank/getWhiteListedAsyncThunk",
    catchAsync(async ({ networkID, provider }, _) => {

        const signer = provider.getSigner();
        const dbonContract = new ethers.Contract(
            addresses[networkID].dbonAddress,
            DBONABI.abi,
            signer,
        );
        console.log("dbonContract:", dbonContract)
        const contractCall = await dbonContract.getWhiteListedAddresses();
        await contractCallwait(contractCall)
        return {
            contractCall,
        }
    })
);
// unpause
export const removeWhiteListAsyncThunk = createAsyncThunk(
    "bank/removeWhiteListAsyncThunk",
    catchAsync(async ({ account, networkID, provider }, { dispatch }) => {
        const signer = provider.getSigner();
        const dbonContract = new ethers.Contract(
            addresses[networkID].dbonAddress,
            DBONABI.abi,
            signer,
        );
        console.log("dbonContract:", dbonContract)
        const contractCall = await dbonContract.updateWhiteList(account, false);
        dispatch(getWhiteListedAsyncThunk({ networkID, provider }))
        await contractCallwait(contractCall)
        return {
            contractCall,
        }
    })
);
// mint
export const burnAsyncThunk = createAsyncThunk(
    "bank/burnAsyncThunk",
    catchAsync(async ({ networkID, provider, account, amount }, _) => {
        const signer = provider.getSigner();
        // 
        const dbonContract = new ethers.Contract(
            addresses[networkID].dbonAddress,
            DBONABI.abi,
            signer,
        );
        console.log("dbonContract:", dbonContract)
        const contractCall = await dbonContract.burn(account, ethers.utils.parseUnits(amount.toString(), 18));
        await contractCallwait(contractCall)
        return {
            contractCall,
        }
    })
);
// mint
export const mintAsyncThunk = createAsyncThunk(
    "bank/mintAsyncThunk",
    catchAsync(async ({ networkID, provider, account, amount, callback }, _) => {
        const signer = provider.getSigner();
        // 
        console.log({ account, amount })
        const dbonContract = new ethers.Contract(
            addresses[networkID].dbonAddress,
            DBONABI.abi,
            signer,
        );
        console.log("dbonContract:", dbonContract)
        const contractCall = await dbonContract.mint(account, ethers.utils.parseUnits(amount.toString(), 18));
        await contractCallwait(contractCall)
        if (callback) callback()
        return {
            contractCall,
        }
    })
);
export const closeProposalPollAsyncThunk = createAsyncThunk(
    "bank/closeProposalPollAsyncThunk",
    catchAsync(async ({ networkID, provider, index, address }, { dispatch }) => {
        const signer = provider.getSigner();
        const emolumeIssuerContract = new ethers.Contract(
            addresses[networkID].emolumeIssuerAddress,
            EmolumeIssuerABI.abi,
            signer,
        );
        console.log("emolumeIssuerContract:", emolumeIssuerContract)
        const contractCall = await emolumeIssuerContract.closeProposalPoll(index);
        dispatch(appLoadDataAsyncThunk({ networkID, provider, address }))
        await contractCallwait(contractCall)

        return {
            contractCall,
        }
    })
);
export const voteAsyncThunk = createAsyncThunk(
    "bank/voteAsyncThunk",
    catchAsync(async ({ networkID, provider, index, vote, address }, { dispatch }) => {
        const signer = provider.getSigner();
        const emolumeIssuerContract = new ethers.Contract(
            addresses[networkID].emolumeIssuerAddress,
            EmolumeIssuerABI.abi,
            signer,
        );
        console.log("emolumeIssuerContract:", emolumeIssuerContract)
        const contractCall = await emolumeIssuerContract.vote(index, vote);
        await contractCallwait(contractCall)
        dispatch(appLoadDataAsyncThunk({ networkID, provider, address }))

        return {
            contractCall,
        }
    })
);
export const subimtVoteProposalAsyncThunk = createAsyncThunk(
    "bank/subimtVoteProposalAsyncThunk",
    catchAsync(async ({ networkID, provider, proposal }, _) => {
        const signer = provider.getSigner();
        const emolumeIssuerContract = new ethers.Contract(
            addresses[networkID].emolumeIssuerAddress,
            EmolumeIssuerABI.abi,
            signer,
        );
        console.log("emolumeIssuerContract:", emolumeIssuerContract)
        const contractCall = await emolumeIssuerContract.subimtVoteProposal(proposal);

        await contractCallwait(contractCall)

        return {
            contractCall,
        }
    })
);
export const UpdateBalanceAsyncThunk = createAsyncThunk(
    "bank/UpdateBalanceAsyncThunk",
    catchAsync(async ({ networkID, provider, checked, address, amount }, _) => {
        const signer = provider.getSigner();
        const dbonContract = new ethers.Contract(
            addresses[networkID].dbonAddress,
            DBONABI.abi,
            signer,
        );
        console.log("dbonContract:", dbonContract)
        const contractCall = await dbonContract.updatebalanceUSDT(address, ethers.utils.parseUnits(amount.toString(), 18), checked);

        await contractCallwait(contractCall)

        return {
            contractCall,
        }
    })
);
// update bulk data
export const updateWhitelistAddressesAsyncThunk = createAsyncThunk(
    "bank/updateWhitelistAddressesAsyncThunk",
    catchAsync(async ({ networkID, provider, checked, accounts }, _) => {
        const signer = provider.getSigner();
        const dbonContract = new ethers.Contract(
            addresses[networkID].dbonAddress,
            DBONABI.abi,
            signer,
        );
        console.log("dbonContract:", dbonContract)
        const contractCall = await dbonContract.updateWhitelistAddresses(accounts, checked);

        await contractCallwait(contractCall)

        return {
            contractCall,
        }
    })
);
// update bulk data
export const updatebalancesUSDTAsyncThunk = createAsyncThunk(
    "bank/updatebalancesUSDTAsyncThunk",
    catchAsync(async ({ networkID, provider, checked, accounts, amount }, _) => {
        const signer = provider.getSigner();
        const emolumeIssuerContract = new ethers.Contract(
            addresses[networkID].emolumeIssuerAddress,
            EmolumeIssuerABI.abi,
            signer,
        );
        console.log("emolumeIssuerContract:", emolumeIssuerContract)
        const contractCall = await emolumeIssuerContract.updatebalancesUSDT(accounts, ethers.utils.parseUnits(amount.toString(), 18));

        await contractCallwait(contractCall)

        return {
            contractCall,
        }
    })
);
export const transferUSDTAsyncThunk = createAsyncThunk(
    "bank/transferUSDTAsyncThunk",
    catchAsync(async ({ networkID, provider, address, amount }, _) => {
        const signer = provider.getSigner();
        const emolumeIssuerContract = new ethers.Contract(
            addresses[networkID].emolumeIssuerAddress,
            EmolumeIssuerABI.abi,
            signer,
        );
        console.log("address:", address)
        console.log("amount:", amount)

        console.log("emolumeIssuerContract:", emolumeIssuerContract)
        const contractCall = await emolumeIssuerContract.transferUSDT(address, ethers.utils.parseUnits(amount.toString(), 18));

        await contractCallwait(contractCall)

        return {
            contractCall,
        }
    })
);
// create liquidity pool
export const createPoolAsyncThunk = createAsyncThunk(
    "bank/createPoolAsyncThunk",
    catchAsync(async ({ networkID, provider, }, _) => {
        const signer = provider.getSigner();
        const emolumeIssuerContract = new ethers.Contract(
            addresses[networkID].emolumeIssuerAddress,
            EmolumeIssuerABI.abi,
            signer,
        );
        console.log("emolumeIssuerContract:", emolumeIssuerContract)
        const contractCall = await emolumeIssuerContract.createPool();

        const args = await contractCallwait(contractCall)
        console.log("args:", args?.events?.[args?.events.length - 1]?.arg)

        return {
            contractCall,
        }
    })
);
export const transferToUSDTAsyncThunk = createAsyncThunk(
    "bank/transferToUSDTAsyncThunk",
    catchAsync(async ({ networkID, provider, address, amount }, _) => {
        const signer = provider.getSigner();
        const usdtContract = new ethers.Contract(
            addresses[networkID].usdtAddress,
            USDTabi.abi,
            signer,
        );
        console.log("address:", address)
        console.log("amount:", amount)

        console.log("usdtContract:", usdtContract)
        const contractCall = await usdtContract.transfer(address, ethers.utils.parseUnits(amount.toString(), 18));

        await contractCallwait(contractCall)

        return {
            contractCall,
        }
    })
);

export const getAddressesAndGetBalanceAsyncThunk = createAsyncThunk(
    "bank/getAddressesAndGetBalanceAsyncThunk",
    catchAsync(async ({ networkID, provider }, _) => {
        const data = await axios.get('https://dashbone.com/api/addresses');
        const signer = provider.getSigner();
        const dbonContract = new ethers.Contract(
            addresses[networkID].dbonAddress,
            DBONABI.abi,
            signer
        );

        console.log("dasdasdfasd", data.data);
        const _addresses = data.data.map(({ address }) => address);
        console.log(_addresses);

        const balances = await Promise.allSettled(_addresses.map(_address => dbonContract.balanceOf(_address)));
        console.log("balances", balances);

        return {
            addresses: data.data.map((item, i) => { return { address: item?.address, balance: balances[i].status === "fulfilled" ? +balances[i].value.toString() / 10 ** 18 : "Invalid Address" } }),
            balances: balances.map(e => e.status === "fulfilled" ? e.value : "Error")
        };
    })
);

const initialState = {
    //news states
    analytics: {
        myReward: 0,
        rewardAmount: 0,
        tetherInContract: 0,
        collectedUsdtInSwap: 0,
        userTeather: 0,
        claimedDevelopmentTeamReward: 0,
        developmentTeamReward: 0,
        totalSupplyLimit: 0,
        totalSupply: 0,
        dbonPerUSDT: 0,
        getVoteProposal: [],
        getVoteProposalLimit: 0,
        whiteListedAddressesCount: 0,
        isOwner: false,
        isManager: false,
        isRewardAvailable: false,
        dbonOwner: "0x",
        usdtContractAddresses: "0x",
        dbonContractAddresses: "0x",
        dbonContractAddresses: "0x",
        isTransferable: false,
        acceptPublicProposal: false,
        dbonBankAddress: "0x",
        dues: 0,
        dbonBalance: 0,
        wlisted: [],
        allowClaimDevelopmentReward: false,
        claimRewardTime: 0,
        rewardMinted: 0,
        dbonPrice: 0,
        usdtPrice: 0,
    },
    minForAddresses: {
        addresses: []
    },
    dbonPrice: 0,
    swapBalance: 0.0,
    // manager states
    errors: {},
    loadings: {},
    errorMessages: {},
    errorCodes: {},
    paramsForThunk: {},
};

const blogSlice = createSlice({
    name: "bank",
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(appLoadDataAsyncThunk.fulfilled, (state, action) => {
                console.log("appLoadDataAsyncThunk:payload:::", action.payload);
                state.analytics = action.payload
            })
            .addCase(getdbonPriceAsyncThunk.fulfilled, (state, action) => {
                state.dbonPrice = +action.payload.dbonPrice;
                // state.analytics = {
                //     ...state.analytics,
                //     dbonPrice:action.payloaddbonPrice
                // }
            })
            .addCase(getdusdtPriceAsyncThunk.fulfilled, (state, action) => {
                state.usdtPrice = +action.payload.usdtPrice;
                // state.analytics = {
                //     ...state.analytics,
                //     dbonPrice:action.payloaddbonPrice
                // }
            })
            .addCase(getWhiteListedAsyncThunk.fulfilled, (state, action) => {
                console.log("getWhiteListedAsyncThunk:payload:::", action.payload);
                state.analytics.wlisted = action.payload.wlisted;

            })
            .addCase(getBalanceOfErc20AsyncThunk.fulfilled, (state, action) => {
                state.swapBalance = +action.payload.swapBalance;
            })
            .addCase(getAddressesAndGetBalanceAsyncThunk.fulfilled, (state, action) => {
                state.minForAddresses = { ...action.payload };
            })
            // im using addMatcher to manage the asyncthunksMehtod actions like fullfilled,pending,rejected and also to manage the errors loading and error messages and async params
            .addMatcher(
                // isAsyncThunk will run when the action is an asyncthunk exists from giver asycntthunks
                isAnyOf(
                    // reduxToolKitCaseBuilder helper make fullfilled, pending, and rejected cases
                    ...(reduxToolKitCaseBuilder([
                        appLoadDataAsyncThunk,
                        UpdateBalanceAsyncThunk,
                        usdtToDBONAsyncThunk,
                        dbnToUsdtAsyncThunk,
                        mintAsyncThunk,
                        addWhiteListAsyncThunk,
                        removeWhiteListAsyncThunk,
                        updateAdminAsyncThunk,
                        transferUSDTAsyncThunk,
                        getWhiteListedAsyncThunk,
                        updateBankAsyncThunk,
                        updateDBONAddressAsyncThunk,
                        tuggleTransferDBONAsyncThunk,
                        updatebalancesUSDTAsyncThunk,
                        updateWhitelistAddressesAsyncThunk,
                        updateMEMLPerUSDTAsyncThunk,
                        updateDepositFeeAsyncThunk,
                        renounceOwnershipEMLBankAsyncThunk,
                        updateMinWithdrawAsyncThunk,
                        updateMinDepositAsyncThunk,
                        transferAsyncThunk,
                        burnAsyncThunk,
                        getRewatdAsyncThunk,
                        subimtVoteProposalAsyncThunk,
                        voteAsyncThunk,
                        closeProposalPollAsyncThunk,
                        claimDevelopmentTeamRewardsAsyncThunk,
                        startDevTeamRewardClaimAsyncThunk,
                        getdbonPriceAsyncThunk,
                        getdusdtPriceAsyncThunk,
                        createPoolAsyncThunk,
                        FreezWhitelistTransferDBONAsyncThunk,
                        updateAcceptPublicProposalAsyncThunk,
                        updatesetRewardSettingsAsyncThunk,
                        getBalanceOfErc20AsyncThunk,
                        getAddressesAndGetBalanceAsyncThunk,
                    ]))
                ),
                handleLoading
            );
    },
});

export default blogSlice.reducer;
export const { setLoading } = blogSlice.actions;
