import React from "react";
import { Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";

export default function CustomButton({
  title,
  type,
  slice,
  action,
  btnClass,
  variant,
  onClick,
  isLoading,
}) {
  const _loading = useSelector((state) => state?.[slice ?? 'cms']?.loadings?.[action]);
  const loading = (isLoading != undefined || isLoading != null ) ? isLoading && _loading : _loading;

  return (
    <button
      type={type}
      disabled={loading}
      className={`${btnClass} btn main-btn`}
      variant={variant}
      onClick={onClick}
      id="button-addon2"
    >
      {loading ? (
        <>
          <Spinner
            // className="spinner-grow spinner-grow-sm"
            animation="border"
            variant="light"
          />
        </>
      ) : (
        title
      )}
    </button>
  );
}
