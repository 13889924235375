// export const basePath = "http://127.0.0.1:8000/api/v1/";
// export const imgBasePath = "http://127.0.0.1:8000/storage/app/public/";

export const basePath = "https://api.perfectprotocol.io/api/v1/";
export const imgBasePath = "https://api.perfectprotocol.io/storage/app/public/";
export const supportedChainIds = [
  // 1337, //localhost
  56, //Binance Smart Chain Mainnet RPC
  // 4002, //fantom testnet
  // 5, //Georily testnet
  // 97, //Binance Smart Chain Testnet RPC
];
export const defaultChainID = 56;
export const addresses = {
  1337: {
    usdtAddress: '0x5FbDB2315678afecb367f032d93F642f64180aa3',
    dbonAddress: '0xe7f1725E7734CE288F8367e1Bb143E90bb3F0512',
    emolumeIssuerAddress: '0x9fE46736679d2D9a65F0992F2272dE9f3c7fa6e0',

  },
  56: {
    usdtAddress: '0x55d398326f99059fF775485246999027B3197955',
    dbonAddress: '0x2A597cDe5f37d4CF2BBD9F902444f7085A977379',
    emolumeIssuerAddress: '0xd9bb87b15ebbc88d4baC298B2699294AAe4C7Cbf',
  },
  4002: {
    usdtAddress: '0x719cfA227A351a0DB391257a34772C3512BbD929',
    dbonAddress: '0x9dd98831DB69993Db951b8a46f2A89D3DF67BBCe',
    emolumeIssuerAddress: '0x14A84f4149D07FcdaA2c660167d5fe9Fc8Fe6d8A',
  },
  97: {
    usdtAddress: '0x58a810f0353d32fc09544d8E1be1042fa274Fad5',
    dbonAddress: '0x57cE7CB87294f0B9A118BAD1270dAA53DB3aF3aC',
    emolumeIssuerAddress: '0x5E30bBc03FdDBA7Be5Cf1A9AA352588A9a507956',
  },
  5: {
    usdtAddress: '0x0C2F918c809F688b120C86Cb6227b2Fe024Dc148',
    dbonAddress: '0x426CBE92e9deC8b6F2f870ae42Dac3e8Cc90bd22',
    emolumeIssuerAddress: '0x6F10c2fE950FADf13261ce4610b0794A23A58E03',
  },
}



export const permissions = {

  // contract admin
  'admin': [
    "Analytics",
    // "transferUSDTTest",
    // "Get Reward",
    "USDT TO DBN",
    "DBN TO USDT",
    "Claim Development Team Rewards",
    "Start Dev Team Reward",
    // 
    "Submit Vote Proposal",
    "Vote",

    "Transfer DBN",
    "Update Whitelist Addresses",
    'Add Whitelist',
    'Remove Whitelist',
    'Change Admin',
    'Transfer USDT',
    "Update Isuer Address in DBN",
    "Update DBN For Bank",
    "Update DBN Per USDT",
    "Tuggle DBN Transfer",
    "Accept Public Proposal",
    "Freez Whitelist Transfer DBN",
    "Remove Ownership of DBN",
    "Mint DBN",
    "Burn DBN",
    "create Pool",
    "Update Reward Settings",
    "Mint Reward",

    // "Swap Bridge",
  ],
  //  RESERVE_ADMIN_ROLE
  'user': [
    "Analytics",
    // "Get Reward",
    "USDT TO DBN",
    "DBN TO USDT",
    "Submit Vote Proposal",
    "Vote",
    "Transfer DBN",
    // "Swap Bridge",
  ],
  'manager': ["Analytics",
  // "Get Reward",
  "USDT TO DBN",
  
  "Submit Vote Proposal",
  "Vote",
  
  "Transfer DBN",
  'Update Balances USDT',
  'Update Balance',
  // "Swap Bridge",
],
  
}
const navLinks = [];

export const APIurls = {
  // auth
  login: "user/login",
  register: "user/register",
  logout: "user/logout",
  //blogs
  news: "news",
  faq: "faq",
  newscategory: "newscategory",
  // getCreatorCollection: (id) => "creator/" + id + "/collections",
  // getCreatorNFTs: (id) => "creator/" + id + "/nfts",
  setting: "setting",
  subscribe: "https://api.perfectprotocol.io/api/subscribe",
  //LandingPage
  landingPage: "landingPage",
  //contact message
  contact: "contact",
  userfeedback: "userfeedback",
  page: "page",

};
