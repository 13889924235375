import React, { useEffect, useState } from 'react'
import { Link, Carousel, Dropdown, Button, InputGroup, Accordion, FormControl, Row, Col, ProgressBar, Form, Navbar, Container, Nav, NavDropdown } from 'react-bootstrap';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { permissions } from '../constants';
import { useWeb3Context } from '../context'
import Methods from '../components/Methods';

// import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { appLoadDataAsyncThunk, getdbonPriceAsyncThunk, usdtToDBONAsyncThunk } from '../redux/pagesSlices/emolume_bank';
import CustomButton from '../components/customs/CustomButton';
import { Web3Button } from '../components/web3UI/Web3Button';

// import { logDOM } from '@testing-library/react';    

AOS.init();
export default function Swap() {
    const loading = useSelector((state) => state?.bank?.loadings?.["getdbonPriceAsyncThunk"]);
    const { web3Provider: provider, address, network, connect } = useWeb3Context()
    const networkID = network?.chainId
    const [validated, setValidated] = useState(false);
    const [usdt, setUSDT] = useState(0);
    const [dbon, setDBON] = useState(0);
    const { analytics, dbonPrice } = useSelector(state => state.bank);
    const { userTeather, dbonBalance } = analytics;
    console.log("userTeather:", userTeather)
    const dispatch = useDispatch();
    // const handleSubmit = (event) => {
    //     event.preventDefault();
    //     const form = event.currentTarget;

    //     if (form.checkValidity() === false) {
    //         event.stopPropagation();
    //         setValidated(true);
    //     } else {
    //         console.log('values',
    //             form["0"]?.value,
    //             form["1"]?.value,
    //         )
    //         dispatch(usdtToDBONAsyncThunk({ amount: form["0"]?.value, networkID, provider }));
    //     }
    // };
    useEffect(() => {
        if (address) {
            dispatch(getdbonPriceAsyncThunk({ amount: usdt, networkID, provider }))
        }
        return () => { }
    }, [usdt])
    useEffect(() => {
        if (address) {
            dispatch(appLoadDataAsyncThunk({ address, networkID, provider }))
        }
        return () => { }
    }, [address])


    return (
        <div>
            <div className='dashboard-body'>
                <div className='container-fluid'>
                    <div className='dashboard-head'>
                        <div className='logo'>
                            <img className='main_logo' src={require('../images/logo.png')} style={{ width: "200px" }} />
                        </div>
                        <div className='buttons'>
                            <a href='/'>
                                <button className='btn main-btn'>Home</button>
                            </a>
                            <Web3Button />
                        </div>
                    </div>
                    <div className='dashboard-main'>
                        <div className='content-body'>
                            {/* <h2 className='dashboard-title'>Dashboard</h2> */}
                            <div className='swiping'>
                                <div className='swip-card'>
                                    <div className='heading2'>
                                        <div className='name'>Swap</div>
                                        <div className='options'>
                                            {/* <Dropdown >
                                                <Dropdown.Toggle variant="dark" id="dropdown-basic">
                                                    <i className="fa-duotone fa-gear"></i>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu variant="dark">
                                                    <Dropdown.Item href="#/action-1">
                                                        Action
                                                    </Dropdown.Item>
                                                    <Dropdown.Item href="#/action-1">
                                                        Action
                                                    </Dropdown.Item>
                                                    <Dropdown.Item href="#/action-1">
                                                        Action
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown> */}

                                        </div>
                                    </div>
                                    <div className='swip-box'>
                                        <Dropdown>
                                            <Dropdown.Toggle variant="dark" id="dropdown-basic">
                                                <img src={require('../images/coin.png')} />

                                                USDT
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu variant="dark">
                                                <Dropdown.Item href="#/action-1">
                                                    <img src={require('../images/coin.png')} />

                                                    USDT
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                        <div className='field-row'>
                                            <div className='left'>
                                                <FormControl
                                                    placeholder="0.00"
                                                    aria-label="name"
                                                    type='number'
                                                    aria-describedby="name"
                                                    required
                                                    onChange={(e) => setUSDT(e.target.value)}
                                                />
                                            </div>
                                            <div className='right'>
                                                <h6>
                                                    Balance: {userTeather.toFixed(2)}
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='swiper-arrow'>
                                        <div className='arrow'>
                                            <i className="fa-duotone fa-arrow-down-arrow-up"></i>
                                        </div>
                                    </div>
                                    <div className='swip-box'>
                                        <Dropdown >
                                            <Dropdown.Toggle variant="dark" id="dropdown-basic">
                                                <img src={require('../images/coin.png')} />

                                                DBN
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu variant="dark">
                                                <Dropdown.Item href="#/action-1">
                                                    <img src={require('../images/coin.png')} />

                                                    DBN
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                        <div className='field-row'>
                                            <div className='left'>
                                                <FormControl
                                                    placeholder="0.00"
                                                    aria-label="name"
                                                    disabled
                                                    value={dbonPrice?.toFixed(2)}
                                                    type='number'
                                                    aria-describedby="name"
                                                    required
                                                />
                                            </div>
                                            <div className='right'>
                                                <h6>
                                                    {dbonBalance && `Balance: ${dbonBalance?.toFixed(2)}`}
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                    <CustomButton
                                        onClick={() => dispatch(usdtToDBONAsyncThunk({ amount: usdt, address, networkID, provider }))}
                                        title="Swap"
                                        type="submit"
                                        slice={'bank'}
                                        action={"usdtToDBONAsyncThunk"}
                                        variant="warning"
                                        btnClass="buy-btn w-100"
                                    />
                                    {/* <button onClick={()=>dispatch(usdtToDBONAsyncThunk({ amount: usdt, networkID, provider }))} className='btn main-btn swap-btn'>Swap</button> */}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
