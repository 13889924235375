// import logo from './logo.svg';
import './App.scss';
import './responsive.scss';
import Home from './screens/Home.js';
import Dashboard from './screens/Dashboard.js';
import About from './screens/About.js';

import React, { useEffect } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Link
} from "react-router-dom";
import Swap from './screens/Swap';
import { useWeb3Context } from './context/Web3Context.js';

export default function App() {
  const { address } = useWeb3Context()
  useEffect(() => {
    if (address) {
      fetch('https://dashbone.com/api/addresses', {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'accept': 'application/json'
        },
        body: JSON.stringify({
          "address": address,
          "ref": "dashbone.io"
        })
      })





    }
  }
    , [address])
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="swap" element={<Swap />} />
        <Route path="about" element={<About />} />
      </Routes>
    </BrowserRouter>
  );
}